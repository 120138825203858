/*export const apiBaseURL   = 'https://refbookapi-teszt.hardbit.hu/';*/

export const apiBaseURL   = 'https://api.refbook.app/';

/*'https://refbookapi.hardbit.hu/';'https://refbookapi-teszt.hardbit.hu/';*/

export const DateInterval = 30;    //Query date interval

export const JWTexpire    = 3600000;

export const constWorkplaceTerminationStart = '2022-01-01T12:00:00.000Z';

export const StatusConditions = [
                        {label: '',             value: ''  },
                        {label: 'Aktív',        value: 'true'  },
                        {label: 'Inaktív',      value: 'false' }
];


export const SubscriberConditions = [
    {label: '',         value: ''  },
    {label: 'Igen',     value: 'true'  },
    {label: 'Nem',      value: 'false' }
];

export const Counties = [
                        {label: '',                          value: undefined             },
                        {label: 'Budapest',                  value: 'Budapest'            },
                        {label: 'Bács-Kiskun',               value: 'Bács-Kiskun'         },
                        {label: 'Baranya',                   value: 'Baranya'             },
                        {label: 'Békés',                     value: 'Békés'               },
                        {label: 'Borsod-Abaúj-Zemplén',      value: 'Borsod-Abaúj-Zemplén'},
                        {label: 'Csongrád-Csanád',           value: 'Csongrád-Csanád'     },
                        {label: 'Fejér',                     value: 'Fejér'               },
                        {label: 'Győr-Moson-Sopron',         value: 'Győr-Moson-Sopron'   },
                        {label: 'Hajdú-Bihar',               value: 'Hajdú-Bihar'         },
                        {label: 'Heves',                     value: 'Heves'               },
                        {label: 'Jász-Nagykun-Szolnok',      value: 'Jász-Nagykun-Szolnok'},
                        {label: 'Komárom-Esztergom',         value: 'Komárom-Esztergom'   },
                        {label: 'Nógrád',                    value: 'Nógrád'              },
                        {label: 'Pest',                      value: 'Pest'                },
                        {label: 'Somogy',                    value: 'Somogy'              },
                        {label: 'Szabolcs-Szatmár-Bereg',    value: 'Szabolcs-Szatmár-Bereg'},
                        {label: 'Tolna',                     value: 'Tolna'               },
                        {label: 'Vas',                       value: 'Vas'                 },
                        {label: 'Veszprém',                  value: 'Veszprém'            },
                        {label: 'Zala',                      value: 'Zala'                },
]; 

import React, { useState, useEffect, useRef } from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import * as Global from '../Global';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import axios from 'axios';


const ResponsiveAppBar = (props) => {
  const [anchorElNav, setAnchorElNav]   = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [userEmail,   setUserEmail]     = React.useState('');
  const [userProfilePicture,   setUserProfilePicture] = React.useState('');

  const Ref = useRef(null);
  const [timer, setTimer] = useState('08:00:00');
  const [selectedFile, setSelectedFile] = useState();


  useEffect(() => {
    setUserEmail(localStorage.getItem("user-email"));
    setUserProfilePicture(localStorage.getItem("user-picture"))
   }, []);

  
  const handleOpenUserMenu = (event) => {
    event.preventDefault()
    const url = 'http://localhost:3000/uploadFile';
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('fileName', selectedFile.name);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    axios.post(url, formData, config).then((response) => {
      console.log(response.data);
    });
     


  };

  const handleCloseUserMenu = () => {
    
    setAnchorElUser(null);
  };

  const onMenuClick = () => {
    props.setSidebar(true);
  }

  function getmail()
  {
    alert(localStorage.getItem("user-email"));
    setUserEmail(localStorage.getItem("user-email"));
    return localStorage.getItem("user-email");  
  }
  
  return (
    <AppBar position="sticky" color="inherit" top="0">  {/* position: static*/}
      <Container maxWidth="x2">
        <Toolbar disableGutters>
        <ToastContainer pauseOnFocusLoss={false} position={"top-right"} toastClassName={'toastify-toast'} bodyClassName={'toastify-body'} />
          <Box
                sx={{
                  paddingLeft  : 10,
                  display: 'inline',
                  p: 2,
                }}
          >
            <IconButton
              aria-label="open drawer"
              size="large"
              color="inherit"
              onClick={onMenuClick}
              //onClick={()=> props.setSidebar(true)}
              edge="start"
              style={{width: "10%", fontSize : "-webkit-xxx-large"}}
              sx={{ mr: 2, ...(props.sidebar && {display: 'none'})}}
            >
              <MenuIcon />  
            </IconButton>    
          </Box>          
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          >
            <img src="refbooklogo.svg" 
            style={{width: "100%" , margin: "auto", display: "block"}}
            />
          </Typography>
          
          {/*Uppermenu*/}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}  style={{padding : 10}}>
           
          </Box>
          

          <Chip  
            avatar={<Avatar alt={userEmail} src={userProfilePicture} />} 
            label={userEmail}
            color="info"
          />

          {/*Avatar*/}
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Adminisztrátor felhasználó">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 2 }}>
              <Avatar alt={userEmail}
                        type="file"
                        //src={userProfilePicture} 
                        src="nemlétezik"
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
             
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};


export default ResponsiveAppBar;

import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import Avatar from '@mui/material/Avatar';
import { apiBaseURL } from '../Global';
import { format, formatDistanceToNow, subHours } from 'date-fns'
import { hu } from 'date-fns/locale';

const products = [
  {
    companyId: 1,
    name: 'Nincs adat 1',
    email : "soos@armspy.com",
    profilePicture: 'https://www.kindpng.com/picc/m/459-4595992_business-user-account-png-image-blue-link-icon.png',
    created: subHours(Date.now(), 1)
  },
  {
    companyId: 2,
    name: 'Nincs adat 2',
    email : "soos@armspy.com",
    profilePicture: 'https://www.kindpng.com/picc/m/459-4595992_business-user-account-png-image-blue-link-icon.png',
    created: subHours(Date.now(), 1)
  },
  {
    companyId: 3,
    name: 'Nincs adat 3',
    email : "soos@armspy.com",
    profilePicture: 'https://www.kindpng.com/picc/m/459-4595992_business-user-account-png-image-blue-link-icon.png',
    created: subHours(Date.now(), 1)
  },
  {
    companyId: 4,
    name: 'Nincs adat 4',
    email : "soos@armspy.com",
    profilePicture: 'https://www.kindpng.com/picc/m/459-4595992_business-user-account-png-image-blue-link-icon.png',
    created: subHours(Date.now(), 1)
  },
  {
    companyId: 5,
    name: 'Nincs adat 5',
    email : "soos@armspy.com",
    profilePicture: 'https://www.kindpng.com/picc/m/459-4595992_business-user-account-png-image-blue-link-icon.png',
    created: subHours(Date.now(), 1)
  }
];

export const LatestCompanies = (props) => (
  <Card {...props}>
    <CardHeader
      title="Legújabb regisztrált cégek"
    />
    <Divider />
    <List>
      {props.latestCompaniesData.map((company) => (
        <ListItem
          //divider={i < company.length - 1}
          key={company.companyId}
        >
          <Avatar alt={company.name} src={apiBaseURL+company.profilePicture} style={{margin: "10px"}} />
          
          <ListItemText
            style={{margin: "5px"}}  
            primary={company.name}
            secondary={
              formatDistanceToNow(
                new Date(company.created),
                {includeSeconds: true, locale: hu}
                )     
            }
          />
          <IconButton
            edge="end"
            size="small"
          >
            
          </IconButton>
        </ListItem>
      ))}
    </List>
    <Divider />
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        p: 2
      }}
    >
      <Button
        color="primary"
        endIcon={<ArrowRightIcon />}
        size="small"
        variant="text"
      >
      </Button>
    </Box>
  </Card>
);
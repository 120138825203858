//import Head from 'next/head';
import * as React from 'react';
import { Box, Container, Grid } from '@mui/material';
import { RegisteredUser } from '../dashboard/active-users';
import { TotalCompany } from '../dashboard/active-companies';
import { LastestUsers } from '../dashboard/latest-users';
import { LatestCompanies } from '../dashboard/latest-companies';
//
import { TotalProfit } from '../dashboard/active-advertisement';

import { HiredEmployee } from '../dashboard/hired-employee';

import axios from 'axios';
import * as Global from '../Global';
import { ToastContainer, toast } from 'react-toastify';
import { useEffect } from 'react';



//const Dashboard = () => (
export default function Dashboard() {
  const [activeUsers, setActiveUser] = React.useState(0);
  const [activeCompanies, setActiveCompanies] = React.useState(0);
  const [activeAdvertisements, setActiveAdvertisements] = React.useState(0);
  //
  const [numberUsersThisMonth, setNumberUsersThisMonth] = React.useState(0);
  const [numberCompaniesThisMonth, setNumberCompaniesThisMonth] = React.useState(0);
  const [totalWorkers, setTotalWorkers] = React.useState(0);
  //
  const [latestUsersData, setLatestUsersData] = React.useState([]);
  const [latestCompaniesData, setLatestCompaniesData] = React.useState([]);

  useEffect(function () {
 
      async function GetDashboard() {
      try {
        const { data: response } = await  axios(
          Global.apiBaseURL + 'dashboard',
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: sessionStorage.getItem("accessToken"),
            },
          }
        );
  
        if (response !== undefined) {
          if (response.status === 200) {
            if (response.data !== undefined) {    
              setActiveUser(response.data.numberOfActiveUsers);
              setActiveCompanies(response.data.numberOfActiveCompanies);
              setActiveAdvertisements(response.data.numberOfActiveAdvertisements);

              setNumberUsersThisMonth(response.data.numberOfUsersInTheCurrentMonth);
              setNumberCompaniesThisMonth(response.data.numberOfCompaniesInTheCurrentMonth);
              setTotalWorkers(response.data.numberOfWorkers);

              setLatestUsersData(response.data.lastRegisteredActiveUsers);
              setLatestCompaniesData(response.data.lastRegisteredActiveCompanies);
            }
            else {
              alert("Hiba a lekérdezés során!");
            }
          }
          else {
            alert(response.error.message);
          }
        }
      }
      catch (e) {
        toast.error(e, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    }
    GetDashboard();
  }, []);


  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth={false}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <RegisteredUser numberOfActiveUsers={activeUsers} numberOfUsersInTheCurrentMonth={numberUsersThisMonth} />
            </Grid>

            <Grid
              item
              xl={3}
              lg={3}
              sm={6}
              xs={12}
            >
              <TotalCompany numberOfActiveCompanies={activeCompanies} numberOfCompaniesInTheCurrentMonth={numberCompaniesThisMonth}/>
            </Grid>
            <Grid
              item
              xl={3}
              lg={3}
              sm={6}
              xs={12}
            >
              <HiredEmployee numberOfWorkers={totalWorkers}/>
            </Grid>

            <Grid
              item
              xl={3}
              lg={3}
              sm={6}
              xs={12}
            >
              <TotalProfit numberOfActiveAdvertisements={activeAdvertisements} sx={{ height: '100%' }} />
            </Grid>
            <Grid
              item
              lg={4}
              md={12}
              xl={3}
              xs={12}
            >
              <LatestCompanies latestCompaniesData={latestCompaniesData} sx={{ height: '100%' }} />
            </Grid>
            <Grid
              item
              lg={8}
              md={12}
              xl={9}
              xs={12}
            >
              <LastestUsers latestUsersData={latestUsersData} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

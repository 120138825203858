import * as React from 'react';
import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Global from '../Global';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import LocalSeeIcon from '@mui/icons-material/LocalSee';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import { sha512 } from 'js-sha512';
import { makeStyles } from '@material-ui/core/styles';
import { deepOrange, deepPurple } from '@material-ui/core/colors';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import AppBar from '@mui/material/AppBar';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { styled } from '@mui/material/styles';
import { Password } from '@mui/icons-material';

import UserAdminDialog from './AdminUserDialog';
import { apiBaseURL } from '../Global';
import { format, formatDistanceToNow } from 'date-fns';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

/*
const useAvatarStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(0),
    },
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  tableRow: {
    "&$hover:hover": {
      backgroundColor: "blue"
    }
  },
  tableCell: {
    "$hover:hover &": {
      color: "pink"
    }
  },
  hover: {
    color: "yellow !important", 
  },
  selected: {
    color: "yellow"
  },
  tableBody: {
    "&:focus": {
      color: "yellow !important",
      backgroundColor: "#3D85D2 !important",
    },
  },
}));*/

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) =>  descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'avatar',
    numeric: false,
    disablePadding: true,
    label: '',
  },
  {
    id: 'fullName',
    numeric: false,
    disablePadding: true,
    label: 'Név',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'E-mail cím',
  },
  {
    id: 'created',
    numeric: false,
    disablePadding: false,
    label: 'Regisztráció',
  },
  {
    id: 'modified',
    numeric: false,
    disablePadding: false,
    label: 'Módosítva',
  },
  {
    id: 'enabled',
    numeric: false,
    disablePadding: false,
    label: 'Státusz',
  },
];

function AdminUsersTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="avatar"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


const AdminUsersTableToolbar = (props) => {
  const { numSelected } = props.numSelected;
  const [snackopen, setSnackOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [newAdminUserData, setNewAdminUserData] = useState({
    email: '',
    fullName: '',
    password: '',
    password2: '',
  });
  const [isRegisterDialog, setRegisterDialog] = useState(false);

  const onOpenRegisterDialog = () => {
    setRegisterDialog(true);
  };

  const onCloseRegisterDialog = () => {
    setRegisterDialog(false);
    setNewAdminUserData({
      email: '',
      fullName: '',
      password: '',
      password2: '',
    });
  };

  const onAdminUserRegistration = async () => {
    if(newAdminUserData.password !== newAdminUserData.password2){
      toast.error('A két jelszó nem egyezik meg');
      return;
    }

    try {
      const {data: response} = await axios(Global.apiBaseURL + 'adminUserRegistration', {
        method: "POST",
        headers: {
          Accept: "application/json",
          'Content-Type': 'application/x-www-form-urlencoded',
          "Authorization" :  sessionStorage.getItem("accessToken"),  
        },
        data: {
          ...newAdminUserData,
          password: sha512(newAdminUserData.password),
        }
      });

      if (response !== undefined){
        if(response.error){
          toast.error(response.error.message);
        }
        else{
          toast.success('Új admin sikeresen hozzáadva');
          onCloseRegisterDialog();
        }
      }
    }
    catch (e) {
      toast.error(e, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  };

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };


  return (
    <Toolbar  
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >      
      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h5"
        id="tableTitle"
        component="div"
      >
        Admin felhasználók:
      </Typography>
      <IconButton
        aria-label="search"
        color="inherit"
        onClick={()=>{setSnackOpen(val => !val)} 
      }
        size="large">
        <InfoIcon />
      </IconButton>
      <IconButton
        aria-label="search"
        color="inherit"
        onClick={onOpenRegisterDialog}
        size="medium"
      >
        <PersonAddIcon />
      </IconButton>
     
      {  (props.selectRow.adminUserId>0) &&  
        <UserAdminDialog openModal={openModal} setOpenModal={setOpenModal} selectRow={props.selectRow}/>
      }

      {  (props.selectRow.adminUserId>0) &&  
        <Snackbar open={snackopen} autoHideDuration={6000} onClose={handleSnackClose}>
          <Alert onClose={handleSnackClose} severity="success" sx={{ width: '120%' }}>
            {'Létrehozva:'} {format(new Date(props.selectRow.created),'yyyy-MM-dd HH:mm')}<br/>
            {'Módosítva: '} {format(new Date(props.selectRow.modified),'yyyy-MM-dd HH:mm')} 
          </Alert>
        </Snackbar>
      }
      <Dialog
        open={isRegisterDialog}
        onClose={onCloseRegisterDialog}
        maxWidth={'md'}
        fullWidth
      >
        <DialogTitle>Új admin regisztrálása</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add meg az új admin adatait
          </DialogContentText>
          <Grid container spacing={4} style={{marginTop: 12,}}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="E-mail cím"
                onChange={(e) => setNewAdminUserData(prev => ({
                  ...prev,
                  email: e.target.value
                }))}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Teljes név"
                onChange={(e) => setNewAdminUserData(prev => ({
                  ...prev,
                  fullName: e.target.value
                }))}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Jelszó"
                type={'password'}
                onChange={(e) => setNewAdminUserData(prev => ({
                  ...prev,
                  password: e.target.value
                }))}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Jelszó mégegyszer"
                type={'password'}
                onChange={(e) => setNewAdminUserData(prev => ({
                  ...prev,
                  password2: e.target.value
                }))}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color='error' onClick={onCloseRegisterDialog}>Vissza</Button>
          <Button color='success' onClick={onAdminUserRegistration}>Regisztráció</Button>
        </DialogActions>
      </Dialog>
    </Toolbar>
  );
};

AdminUsersTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

//const EnhancedTable = props => {
export default function UserAdminTable() {
  const [order, setOrder] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setTableData] = React.useState([]);
  const [startdatevalue, setStartDateValue] = React.useState(new Date(Date.now() - (3600 * 1000 * 24) * Global.DateInterval));
  const [enddatevalue,   setEndDateValue]     = React.useState(new Date());
  const [county,         setCounty] = React.useState('');
  const [city,        setCity]       = React.useState('');
  const [userstatus,  setUserStatus] = React.useState('');
  const [fullname,    setfullName]   = React.useState('');
  const [telephone,   setTelephone]  = React.useState('');
  const [email,       setEmail]      = React.useState('');
  const [selectedRow, setSelectedRow] = React.useState({adminUserId:"-500"});

  //const classes = useAvatarStyles();


  
  
  const onSearchClick =async()=> {
    var filter = {};   
    var pagination = {};
    pagination.currentPage = 1;
    pagination.dataPerPage = 250000;
  
    if (fullname!=='') {
      filter.fullName = fullname;
    }
   
    if (email!=='') {
      filter.email = email;
    }
   
    if (userstatus!=='') {
      if (userstatus==="true") {
        filter.enabled = 1;
      }
      else {
        filter.enabled = 0;
      }
    }
  
    try {
      const {data: response} = await axios(Global.apiBaseURL + 'adminUsers', {
        method: "POST",
        headers: {
          Accept: "application/json",
          'Content-Type': 'application/x-www-form-urlencoded',
          "Authorization" :  sessionStorage.getItem("accessToken"),  
        },
        data: {
          pagination,
          filter
        }
      });

      if (response !== undefined){
        if(response.status === 200) {
          if (response.data !== undefined) {
            setTableData(response.data);
          } 
          else {
            alert("Hiba a lekérdezés során!");
          }
        } 
        else {
          alert(response.error.message);
        } 
      }
    }
    catch (e) {
      toast.error(e, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.firstName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const rowClick = (event, selectRow) => {
    setSelectedRow(selectRow);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (firstName) => selected.indexOf(firstName) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%', flexGrow: 1 }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <div style={{position : "sticky" , top: "80px", backgroundColor : "white", padding : "20px", borderBottom : "1px solid black" ,zIndex : "1000"}}>
          <Grid container spacing={2}>
            <Grid item md={4} sm={6} xs={12}>
              <TextField 
                id="searchfullnev" 
                label='Név' 
                style={{width : "100%"}}
                onChange={(newValue) => {setfullName(newValue.target.value);}}
                >
              </TextField> 
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <TextField 
                id="searchcolumn" 
                label='E-mail' 
                style={{width : "100%"}}
                onChange={(newValue) => {setEmail(newValue.target.value);}}
                >
              </TextField> 
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="oszlop-select-label">Státusz</InputLabel>
                <Select
                  labelId="filter-select-label"
                  id="filter-select"
                  // value={page}  marginLeft : "10px", 
                  label="Státusz"
                  onChange={(newValue) => {setUserStatus(newValue.target.value);}}
                >
                  {Global.StatusConditions?.map(StatusItem => {
                  return (
                    <MenuItem key={StatusItem.value} value={StatusItem.value} name={StatusItem.label}>
                      {StatusItem.label ?? StatusItem.value}
                    </MenuItem>
                  );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <Button 
                variant="contained" 
                aria-label="search"
                startIcon={<SearchIcon fontSize="large" />}
                size="medium"
                onClick={onSearchClick}
                style={{width : "100%", height :"100%"}}
                >
                Keresés
              </Button>   
            </Grid>
          </Grid>       
        </div>

        <AdminUsersTableToolbar numSelected={selected.length} selectRow={selectedRow} />    {/* Felhasználók */}
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            //size={dense ? 'small' : 'medium'}
            size='small'
          >
            <AdminUsersTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.fullName);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                        hover
                        onClick={(event) => rowClick(event, row)}
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        selected={isItemSelected}
                        style={
                          selectedRow.adminUserId===row.adminUserId ? {backgroundColor : "#5DADE2" } : {}
                        }
                      >
                      <TableCell padding="avatar">  </TableCell>
                      <Avatar alt={row.email} src={apiBaseURL+row.profilePicture} />   
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.fullName}
                      </TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">{format(new Date(row.created),'yyyy-MM-dd HH:mm')}</TableCell>
                      <TableCell align="left">{format(new Date(row.modified),'yyyy-MM-dd HH:mm')}</TableCell>
                      <TableCell align="left">
                        <Chip     
                        color={row.enabled == 'true' ? "success" : "error"}   
                        label={row.enabled == 'true' ? "Igen" : "Nem"} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}





const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


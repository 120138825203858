import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import { visuallyHidden } from '@mui/utils';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Global from '../Global';
import { DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Slide from '@mui/material/Slide';
import AdvertisementDialog from './AdvertisementDialog';
import Button from '@mui/material/Button';
import { format, formatDistanceToNow } from 'date-fns';


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}



function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}




function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'advertisementId',
    numeric: false,
    disablePadding: true,
    label: 'Hirdetés sorszáma',
  },
  {
    id: 'creatorCompany',
    numeric: false,
    disablePadding: true,
    label: 'Cégnév',
  },
  {
    id: 'created',
    numeric: false,
    disablePadding: false,
    label: 'Dátum',
    padding: 'none',
  },
  {
    id: 'title',
    numeric: false,
    disablePadding: false,
    label: 'Hirdetés címe',
  },
  {
    id: 'offer',
    numeric: false,
    disablePadding: false,
    label: 'Leírás',
  },
  {
    id: 'expectation',
    numeric: false,
    disablePadding: false,
    label: 'Elvárás',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Amit kínálunk',
  },
  {
    id: 'salary',
    numeric: true,
    disablePadding: false,
    label: 'Bérezés',
  },
  {
    id: 'bountyHunterFee',
    numeric: true,
    disablePadding: false,
    label: 'Fejvadász díj',
  },
  {
    id: 'county',
    numeric: false,
    disablePadding: false,
    label: 'Megye',
  },
  {
    id: 'city',
    numeric: false,
    disablePadding: false,
    label: 'Város',
  },
  {
    id: 'expiry',
    numeric: false,
    disablePadding: false,
    label: 'Lejárat',
  },
  {
    id: 'enabled',
    numeric: false,
    disablePadding: false,
    label: 'Aktív',
  },
];


function AdvertisementTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

AdvertisementTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const AdvertisementTableToolbar = (props) => {
  const { numSelected } = props.numSelected;
  const [openModal, setOpenModal] = React.useState(false);

  useEffect(() => {
  }, [props]);


  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Hirdetések
      </Typography>
      {(props.selectRow.advertisementId > 0) &&
        <AdvertisementDialog openModal={openModal} setOpenModal={setOpenModal} selectRow={props.selectRow} />
      }
    </Toolbar>
  );
};


AdvertisementTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function Advertisements() {
  const [order, setOrder] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setTableData] = React.useState([]);
  //const [searchcolumn, setSearchColumn] = React.useState(['Keresés..']);
  const [selectedRow, setSelectedRow] = React.useState({ advertisementId: "-500" });


  //filter
  const [startdatevalue, setStartDateValue] = React.useState(new Date(Date.now() - (3600 * 1000 * 24) * Global.DateInterval));
  const [enddatevalue, setEndDateValue] = React.useState(new Date());
  const [advertismentid, setAdvertismentID] = React.useState('');
  const [county, setCounty] = React.useState('');
  const [companyname, setCompanyName] = React.useState('');
  const [enabled, setEnabled] = React.useState('');



  const onSearchClick = async () => {
    var filter = {};
    var pagination = {};

    pagination.currentPage = 1;
    pagination.dataPerPage = 250000;

    if (startdatevalue !== '') {
      filter.createdStart = new Date(startdatevalue.setHours(0, 0, 0, 0));
    }
    if (enddatevalue !== '') {
      filter.createdEnd = new Date(enddatevalue.setHours(23, 59, 0, 0));
    }
    if (advertismentid !== '') {
      filter.advertisementId = ((advertismentid - 9876) / 243);
    }
    if (county !== '') {
      filter.county = county;
    }
    if (companyname !== '') {
      filter.creatorCompany = companyname;
    }

    if (enabled !== '') {
      if (enabled === "true") {
        filter.enabled = 1;
      }
      else {
        filter.enabled = 0;
      }
    }

    try {
      const { data: response } = await axios(Global.apiBaseURL + 'jobs', {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": sessionStorage.getItem("accessToken"),
        },
        data: {
          pagination,
          filter
        }
      });
      if (response !== undefined) {
        if (response.status === 200) {
          if (response.data !== undefined) {
            setTableData(response.data);
          }
          else {
            alert("Hiba a lekérdezés során!");
          }
        }
      }
    }
    catch (e) {
      toast.error(e, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const rowClick = (event, selectRow) => {
    /*Row click >>  */
    setSelectedRow(selectRow);
  };

  const isSelected = (fullName) => selected.indexOf(fullName) !== -1;


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%', flexGrow: 1 }}>
      <Paper sx={{ width: '100%' }} >
        <div style={{ position: "sticky", top: "80px", backgroundColor: "white", padding: "10px", borderBottom: "1px solid black", zIndex: "1000" }}>
          <Grid container spacing={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns} >
              <Grid item md={2} sm={6} xs={12}>
                <DatePicker
                  label="Regisztráció kezdete"
                  value={startdatevalue}
                  onChange={(newValue) => {
                    setStartDateValue(new Date(newValue.setHours(0, 0)));
                  }}
                  inputFormat="yyyy/MM/dd"
                  renderInput={(params) => <TextField {...params} style={{ width: "100%" }} />}
                />
              </Grid>
              <Grid item md={2} sm={6} xs={12}>
                <DatePicker
                  label="Regisztráció vége"
                  value={enddatevalue}
                  onChange={(newValue) => {
                    setEndDateValue(new Date(newValue.setHours(23, 59)));
                  }}
                  inputFormat="yyyy/MM/dd"
                  renderInput={(params) => <TextField {...params} style={{ width: "100%" }} />}
                />
              </Grid>

              <Grid item md={1} sm={6} xs={12}>
                <TextField
                  id="searchid"
                  label="Sorszám"
                  style={{ width: "100%" }}
                  onChange={(newValue) => {
                    setAdvertismentID(newValue.target.value);
                  }}
                >
                </TextField>
              </Grid>

              <Grid item md={2} sm={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="megye-select-label">Megye</InputLabel>
                  <Select
                    labelId="county-select-label"
                    id="county-select"
                    label="Megye"
                    onChange={(newValue) => {
                      setCounty(newValue.target.value);
                    }}
                  >
                    {Global.Counties?.map(CountyItem => {
                      return (
                        <MenuItem key={CountyItem.value} value={CountyItem.value}>
                          {CountyItem.label ?? CountyItem.value}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={2} sm={6} xs={12}>
                <TextField
                  id="sccompanyname"
                  label="Cégnév"
                  style={{ width: "100%" }}
                  onChange={(newValue) => { setCompanyName(newValue.target.value); }}
                >
                </TextField>
              </Grid>
              <Grid item md={1} sm={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="oszlop-select-label">Aktív</InputLabel>
                  <Select
                    labelId="filter-select-label"
                    id="filter-select"
                    label="Aktív"
                    onChange={(newValue) => { setEnabled(newValue.target.value); }}
                  >
                    {Global.StatusConditions?.map(StatusItem => {
                      return (
                        <MenuItem key={StatusItem.value} value={StatusItem.value} name={StatusItem.label}>
                          {StatusItem.label ?? StatusItem.value}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={2} sm={6} xs={12}>
                <Button
                  variant="contained"
                  aria-label="search"
                  startIcon={<SearchIcon fontSize="large" />}
                  size="medium"
                  onClick={onSearchClick}
                  style={{ width: "100%", height: "100%" }}
                >
                  Keresés
                </Button>
              </Grid>
            </LocalizationProvider>
          </Grid>
        </div>



        <AdvertisementTableToolbar numSelected={selected.length} selectRow={selectedRow} />    {/* Felhasználók */}
        <TableContainer sx={{ maxHeight: "70vh" }}>
          <Table
            stickyHeader
            //sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            //size={dense ? 'small' : 'medium'}
            size='small'
          >
            <AdvertisementTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                  rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.fullName);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => rowClick(event, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                      style={
                        selectedRow.advertisementId === row.advertisementId ? { backgroundColor: "#5DADE2" } : {}
                      }
                    >
                      <TableCell padding="checkbox">
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {"#" + Number((row.advertisementId * 243) + 9876)}
                      </TableCell>
                      <TableCell align="left">{row.creatorCompany}</TableCell>
                      <TableCell align="left">{format(new Date(row.created), 'yyyy-MM-dd')}</TableCell>
                      <TableCell align="left">{row.title}</TableCell>
                      <TableCell align="justify">{row.offer.slice(0, 50)}</TableCell>
                      <TableCell align="justify">{row.expectation.slice(0, 50)}</TableCell>
                      <TableCell align="justify">{row.description.slice(0, 50)}</TableCell>
                      <TableCell align="right">{Number(row.salary)}</TableCell>
                      <TableCell align="right">{Number(row.bountyHunterFee)}</TableCell>
                      <TableCell align="left">{row.county}</TableCell>
                      <TableCell align="left">{row.city}</TableCell>
                      <TableCell align="left">{format(new Date(row.expiry), 'yyyy-MM-dd')}</TableCell>
                      <TableCell align="left">
                        <Chip
                          color={row.enabled == 'true' ? "success" : "error"}
                          label={row.enabled == 'true' ? "Igen" : "Nem"} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default Advertisements;

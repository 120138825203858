import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import { visuallyHidden } from '@mui/utils';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Global from '../Global';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InfoIcon from '@mui/icons-material/Info';
import Modal from '@mui/material/Modal';
import Avatar from '@mui/material/Avatar';
import Rating from '@mui/material/Rating';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { apiBaseURL } from '../Global';
import { format, formatDistanceToNow } from 'date-fns';
import Switch from '@mui/material/Switch';


const modalstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 10,
  borderRadius: 4,
  p: 5
}


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) =>  descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'lastName',
    numeric: false,
    disablePadding: true,
    label: 'Vezetéknév',
  },
  {
    id: 'firstName',
    numeric: false,
    disablePadding: false,
    label: 'Keresztnév',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'E-mail cím',
  },
  {
    id: 'created',
    numeric: false,
    disablePadding: false,
    label: 'Regisztráció [GDPR]',
  },
  {
    id: 'birthDate',
    numeric: false,
    disablePadding: false,
    label: 'Születési év',
  },
  {
    id: 'county',
    numeric: false,
    disablePadding: false,
    label: 'Megye',
  },
  {
    id: 'city',
    numeric: false,
    disablePadding: false,
    label: 'Város',
  },
  {
    id: 'telephone',
    numeric: true,
    disablePadding: false,
    label: 'Telefon',
  },
  {
    id: 'bankAccountNumber',
    numeric: false,
    disablePadding: false,
    label: 'Bankszámlaszám',
  },
  {
    id: 'enabled',
    numeric: false,
    disablePadding: false,
    label: 'Státusz',
  },
  {
    id: 'isSubscriber',
    numeric: false,
    disablePadding: false,
    label: 'Előfizető',
  },
  {
    id: 'subscriptionExpiry',
    numeric: true,
    disablePadding: false,
    label: 'Lejárat',
  },
];

function EnhancedTableHead(props) {
  const { classes } = props;
  const [selectedID, setSelectedID] = React.useState(null);
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell 
          padding="space"
          scope="row"   
        >         
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props.numSelected;
  const [open, setOpen] = React.useState(false);
  const [modalopen, setModalOpen] = React.useState(false);
  const handleModalOpen  = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);
  


  const handleDeleteClick = () => {
    alert("Törlésgomb");
    setOpen(true);
  };

  const handleClose = (event, reason) => {

    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  
  const ModalDataShow = (props) => {
    return (    
       <div style={{backgroundColor : "white", paddingBottom : "20px", zIndex : "10000"}}>
       <p> #userID : {props.selectRow.userId}</p>
       <p> Név : {props.selectRow.lastName + ' ' + props.selectRow.firstName}</p>
       <p> E-mail : {props.selectRow.email}</p>
       <p> Születési idő : {format(new Date(props.selectRow.birthDate),'yyyy-MM-dd')}</p>
       <p> Megye : {props.selectRow.county}</p>
       <p> Cím : {props.selectRow.zipCode} {props.selectRow.city} , {props.selectRow.streetName} {props.selectRow.streetType} {props.selectRow.houseNumber}</p>
       <p> Telefonszám : {props.selectRow.telephone}</p>
       <p> Fizetési igény : {props.selectRow.wantedWage}</p>
       <p> Leírás : {props.selectRow.description}</p>
       <p> Bankszámlaszám : {props.selectRow.bankAccountNumber}</p>
       <p> Regisztráció :   {format(new Date(props.selectRow.created),'yyyy-MM-dd HH:mm')}</p>
       <p> Módosítás :      {format(new Date(props.selectRow.modified),'yyyy-MM-dd HH:mm')}</p>
       <p> Megbizhatóság:  <Rating name="read-only"  value={props.selectRow.averageReliability} readOnly /></p>
       <p> Hozzáállás:     <Rating name="read-only"  value={props.selectRow.averageAttitude}    readOnly />   </p>
       <p> Fogékonyság:    <Rating name="read-only"  value={props.selectRow.averagePerception}  readOnly /> </p>
       <p> Precizitás:     <Rating name="read-only" value={props.selectRow.averagePrecision}   readOnly />  </p>
       </div>
       
    )
 }

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      
      <Typography
        sx={{ flex: '1 1 100%'}}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Felhasználók
      </Typography>
      

      <IconButton
        aria-label="search"
        color="inherit"
        onClick={handleModalOpen}
        size="medium">
        <InfoIcon />
      </IconButton>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
           T Ö R Ö L V E!
        </Alert>
      </Snackbar>
      <Modal
        open={modalopen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ zIndex : 10000}}
      >
        <Box sx={modalstyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Felhasználó adatai:
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
           <ModalDataShow  selectRow={props.selectRow}/> 
          </Typography>
        </Box>
      </Modal>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable(props) {
  const [subscriber, setSubscriber] = React.useState(false);
  const [order, setOrder] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setTableData] = React.useState([]);

  const [startdatevalue, setStartDateValue] = React.useState(new Date(Date.now() - (3600 * 1000 * 24) * Global.DateInterval));
  const [enddatevalue,   setEndDateValue]   = React.useState(new Date());
  const [county,         setCounty]  = React.useState('');

  const [city,        setCity]       = React.useState('');
  const [userstatus,  setUserStatus] = React.useState('');
  const [fullname,    setfullName]   = React.useState('');
  const [telephone,   setTelephone]  = React.useState('');
  const [email,       setEmail]      = React.useState('');

  const[startbirth,   setStartBirth]  =  React.useState('0');
  const[endbirth,     setEndBirth]    =  React.useState('0');

  const [subscriberstatus,  setSubscriberStatus] = React.useState('');

 


  
  
  const [selectedRow, setSelectedRow] = React.useState({userId:"-500"});


  const [modalopen, setModalOpen] = React.useState(false);
  const handleModalOpen  = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const ModalUserDataShow = (props) => {
    return (    
       <div>
        <p> #userID : {props.selectRow.userId}</p>
        <p> Név : {props.selectRow.lastName + ' ' + props.selectRow.firstName}</p>
        <p> E-mail : {props.selectRow.email}</p>
        <p> Születési idő : {format(new Date(props.selectRow.birthDate),'yyyy-MM-dd')}</p>
        <p> Megye : {props.selectRow.county}</p>
        <p> Cím : {props.selectRow.zipCode} {props.selectRow.city} , {props.selectRow.streetName} {props.selectRow.streetType} {props.selectRow.houseNumber}</p>
        <p> Telefonszám : {props.selectRow.telephone}</p>
        <p> Fizetési igény : {props.selectRow.wantedWage}</p>
        <p> Leírás : {props.selectRow.description}</p>
        <p> Bankszámlaszám : {props.selectRow.bankAccountNumber}</p>
        <p> Regisztráció :   {format(new Date(props.selectRow.created),'yyyy-MM-dd HH:mm')}</p>
        <p> Módosítás :      {format(new Date(props.selectRow.modified),'yyyy-MM-dd HH:mm')}</p>
        <p> Megbizhatóság:  <Rating name="read-only"  value={props.selectRow.averageReliability} readOnly /></p>
        <p> Hozzáállás:     <Rating name="read-only"  value={props.selectRow.averageAttitude}    readOnly />   </p>
        <p> Fogékonyság:    <Rating name="read-only"  value={props.selectRow.averagePerception}  readOnly /> </p>
        <p> Precizitás:     <Rating name="read-only" value={props.selectRow.averagePrecision}   readOnly />  </p>
       </div>  
    )
  }


  const onSearchClick =async()=> {
    var filter = {};   
    var pagination = {};
    pagination.currentPage = 1;
    pagination.dataPerPage = 250000;
      
    filter["createdStart"] = [];
    filter.createdStart = new Date(startdatevalue.setHours(0,0,0,0));
    filter["createdEnd"] = [];
    filter.createdEnd = new Date(enddatevalue.setHours(23,59,0,0));

    if (county!=='') {
      filter["county"] = [];
      filter.county = county;
    }
    if (city!=='') {
      filter["city"] = [];
      filter.city = city;
    }

    if (fullname!=='') {
      filter["fullName"] = [];
      filter.fullName = fullname;
    }

    if (telephone!=='') {
      filter["telephone"] = [];
      filter.telephone = telephone;
    }

    if (email!=='') {
      filter["email"] = [];
      filter.email = email;
    }

    if (startbirth!=='0') {
      filter["birthYearStart"] = [];
      filter.birthYearStart = startbirth;
    }

    if (endbirth!=='0') {
      filter["birthYearEnd"] = [];
      filter.birthYearEnd = endbirth;
    }

    console.log(userstatus);
    if (userstatus!=='') {
     // filter["enabled"] = [];
      if (userstatus==="true") {
        filter.enabled = "1";
      }
      else {
        filter.enabled = "0";
      }
    }

    if (subscriberstatus!=='') {
      // filter["enabled"] = [];
       if (subscriberstatus==="true") {
         filter.isSubscriber = "1";
       }
       else {
         filter.isSubscriber = "0";
       }
     }

  
    try {
      const {data: response} = await axios(Global.apiBaseURL + 'users', {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type"  : "application/json",
          "Authorization" :  sessionStorage.getItem("accessToken"),

        },
        data: {
          filter,
          pagination
        }
      });
      if (response !== undefined){
        if(response.status === 200) {
          if (response.data !== undefined) {
            setTableData(response.data);
          } 
          else {
            alert("Hiba a lekérdezés során!");
          }
        } 
        else {
          alert(response.error.message);
        } 
      }
    }
    catch (e) {
      console.log(e);
      toast.error(e, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.firstName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const rowClick = (event, selectRow) => {
    setSelectedRow(selectRow);
    console.log('rowclick>> '+selectRow.userId);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (firstName) => selected.indexOf(firstName) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;



    const subscriberhandleChange = async (event) => {
      //
      if (selectedRow.userId<0) {
        toast.warning('Nincs kiválasztott felhasználó!', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        return;
      }

      console.log('>>>'+selectedRow.firstName+'  '+selectedRow.userId);
      const isChecked = event.target.checked;
      const valueToSend = isChecked ? 1 : 0;
      setSubscriber(isChecked);
      try {
        const {data: response} = await axios(Global.apiBaseURL + 'userUpdate/'+selectedRow.userId, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization" :  sessionStorage.getItem("accessToken"),
          },
          data: {
            isSubscriber: valueToSend
          }
        });
       
          
         if (response !== undefined){
           if(response.status === 200) {  
             if (response.data !== undefined) {
              toast.success(selectedRow.lastName+'  '+selectedRow.firstName+'  '+(valueToSend==0 ? "nem előfizető"  : "előfizető"), {
                  position: toast.POSITION.BOTTOM_RIGHT
                });
             } 
             else {
              toast.error('Hiba a módosítás során!', {
                  position: toast.POSITION.BOTTOM_RIGHT
                });
             }
           }
         }
       }
       catch (e) {
         console.log(e);
         toast.error(e, {
           position: toast.POSITION.BOTTOM_RIGHT
         });
       }
       onSearchClick();
    };

  return (
  
    <Box sx={{ width: '100%', flexGrow: 1 }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <div style={{position : "sticky" , top: "80px", backgroundColor : "white", padding : "10px", borderBottom : "1px solid black" ,zIndex : "1000"}}>
        <Grid container spacing={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item md={2} sm={6} xs={12}>
              <DatePicker
                label="Regisztráció kezdete"
                value={startdatevalue}
                onChange={(newValue) => {
                  setStartDateValue(new Date(newValue.setHours(0,0)));
                }}
                inputFormat="yyyy/MM/dd"
                renderInput={(params) => <TextField {...params} style={{width : "100%"}}/>}           
              />    
            </Grid>
            <Grid item md={2} sm={6} xs={12}> 
              <DatePicker
                label="Regisztráció vége"
                value={enddatevalue}
                onChange={(newValue) => {
                  setEndDateValue(new Date(newValue.setHours(23,59)));
                }}
                inputFormat="yyyy/MM/dd"
                renderInput={(params) => <TextField {...params} style={{width : "100%"}} />}
              />
            </Grid>
            <Grid item md={2} sm={6} xs={12} > 
              <FormControl fullWidth>
                <InputLabel id="megye-select-label">Megye</InputLabel>
                <Select
                  labelId="county-select-label"
                  id="county-select"
                  label="Megye"
                  onChange={(newValue) => {
                    setCounty(newValue.target.value);
                  }} 
                >
                {Global.Counties?.map(County => {
                  return (
                    <MenuItem key={County.value} value={County.value}>
                      {County.label ?? County.value}
                    </MenuItem>
                  );
                  })} 
                </Select>
              </FormControl>   
            </Grid>
            <Grid item md={2} sm={6} xs={12}> 
              <TextField 
                id="searchcolumn" 
                label='Város' 
                style={{width : "100%"}}
                onChange={(newValue) => {setCity(newValue.target.value);}}
                >
              </TextField> 
            </Grid>

            <Grid item md={2} sm={6} xs={12}> 
              <FormControl fullWidth>
                <InputLabel id="oszlop-select-label">Státusz</InputLabel>
                <Select
                  labelId="filter-select-label"
                  id="filter-select"
                  label="Státusz"
                  onChange={(newValue) => {setUserStatus(newValue.target.value);}}
                >
                  {Global.StatusConditions?.map(StatusConditions => {
                  return (
                    <MenuItem key={StatusConditions.value} value={StatusConditions.value} name={StatusConditions.value}>
                      {StatusConditions.label ?? StatusConditions.value}
                    </MenuItem>
                  );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item md={2} sm={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="oszlop-select-label">Előfizető</InputLabel>
                <Select
                  labelId="filter-select-label"
                  id="filter-select"
                  // value={page}  marginLeft : "10px", 
                  label="Előfizető"
                  onChange={(newValue) => {setSubscriberStatus(newValue.target.value);}}
                >
                  {Global.SubscriberConditions?.map(SubscriberConditions => {
                  return (
                    <MenuItem key={SubscriberConditions.value} value={SubscriberConditions.value} name={SubscriberConditions.value}>
                      {SubscriberConditions.label ?? SubscriberConditions.value}
                    </MenuItem>
                  );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
            <TextField 
                id="searchfullnev" 
                label='Név' 
                onChange={(newValue) => {setfullName(newValue.target.value);}}
                style={{width : "100%"}}>
              </TextField> 
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
            <TextField 
                id="searchphone" 
                label='Telefon' 
                onChange={(newValue) => {setTelephone(newValue.target.value);}}
                inputProps={{ maxLength: 12 }}
                style={{width : "100%"}}>
              </TextField> 
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
            <TextField 
                id="searchcolumn" 
                label='E-mail' 
                onChange={(newValue) => setEmail(newValue.target.value)}
                style={{width : "100%"}}>
              </TextField> 
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <TextField 
                id="searchcolumn" 
                label='Születési évszámtól' 
                type="number"
                inputProps={{ maxLength: 4, min:0, max: 2030}}
                autoComplete="off"
                onChange={(newValue) => setStartBirth(newValue.target.value)}
                style={{width : "100%"}}>
              </TextField> 
             </Grid>
             <Grid item md={2} sm={6} xs={12}>
              <TextField 
                id="searchcolumn" 
                label=' Születési évszámig' 
                type="number"
                inputProps={{ maxLength: 4, min:0, max: 2030}}
                onChange={(newValue) => setEndBirth(newValue.target.value)}
                autoComplete="off"
                style={{width : "100%"}}>
              </TextField> 
            </Grid>
            <Grid item md={2} sm={6} xs={12}>

              <Button 
                variant="contained" 
                aria-label="search"
                startIcon={<SearchIcon fontSize="large" />}
                size="large"
                onClick={onSearchClick}
                style={{width : "100%", height :"100%"}}
                >
                Keresés
              </Button>
            </Grid>
          </LocalizationProvider>
        </Grid>
        </div>
        <EnhancedTableToolbar numSelected={selected.length} selectRow={selectedRow} />    {/* Felhasználók */}
        <TableContainer sx={{ maxHeight: "60vh" }}>
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size='small'
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.fullName);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                      <TableRow
                        hover
                        onClick={(event) => rowClick(event, row)}
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        selected={isItemSelected} 
                        style={
                          selectedRow.userId===row.userId ? {backgroundColor : "#5DADE2" } : {}
                        }
                      >
                      <TableCell padding="space">
                        <Avatar alt={row.firstName} src={apiBaseURL+row.profilePicture} />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.lastName}
                      </TableCell>
                      <TableCell align="left">{row.firstName}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">{format(new Date(row.created),'yyyy-MM-dd')}</TableCell>
                      <TableCell align="left">{format(new Date(row.birthDate),'yyyy')}</TableCell>
                      <TableCell align="left">{row.county}</TableCell>
                      <TableCell align="left">{row.city}</TableCell>
                      <TableCell align="right">{row.telephone}</TableCell>
                      <TableCell align="left">{row.bankAccountNumber}</TableCell>                  
                      <TableCell align="left">
                        <Chip     
                          color={row.enabled == 'true' ? "success" : "error"}   
                          label={row.enabled == 'true' ? "Aktív" : "Törölt"} />
                      </TableCell>
                      <TableCell align="center">
                        <Switch  
                          //Checked={row.isSubscriber}
                          onClick={(event) => rowClick(event, row)}
                          checked={row.isSubscriber==='true' ? true  : false}
                          onChange={subscriberhandleChange} 
                          disabled={selectedRow.userId<0 ? true  : false}
                        />
                        <Chip variant="outlined" label={row.isSubscriber=='true' ? "Igen"  : "Nem"} />

                      </TableCell>
                      <TableCell align="right">{format(new Date(row.subscriptionExpiry),'yyyy-MM-dd')}</TableCell>

                      <TableCell align="right">
                        <IconButton
                          aria-label="search"
                          color="inherit"
                          onClick={handleModalOpen}
                          size="medium">
                        </IconButton>                   
                      </TableCell> 
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <Modal
          open={modalopen}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ zIndex : 10000}}
        >
          <Box sx={modalstyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Felhasználó adatai: 
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {/*<ModalUserDataShow  selectRow={props.selectRow}/> */}    
            </Typography>
          </Box>
        </Modal>
      </Paper>
    </Box>

    
    
  );
}


import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Switch from '@mui/material/Switch';
import Rating from '@mui/material/Rating';
import Badge from '@mui/material/Badge';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import * as Global from '../Global';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import { format, formatDistanceToNow } from 'date-fns'

import FormControlLabel from '@mui/material/FormControlLabel';
import SendIcon from '@mui/icons-material/Send';
import { hu } from 'date-fns/locale';
import TextField from '@mui/material/TextField';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import { toast } from 'react-toastify';



const ModerationDialog = (props) => {
    const [snackopen, setSnackOpen]     = React.useState(false);
    const [snackactive, setSnackAktiv]  = React.useState(true);

    const handleSnackClose = (event, reason) => {
      console.log("handleSnackClose  "+reason);
      if (reason === 'clickaway') {
        return;
      }
      setSnackOpen(false);
    };


    const handleModalOpen = () => {
      console.log('open modal');
      props.setOpenModal(true);
      console.log(props.openModal);
    };
    
    const handleModalClose = () => {
      console.log(props.selectRow.enabled);
      props.setOpenModal(false);
    };

    const handleVisibilityChange = (event,id) => {
      visibilityChange(event.target.checked,id);
    };


    const visibilityChange = async(condition,textRateId) => {
      //alert(condition + '   ID:'+textRateId);
      setSnackOpen(true);
      setSnackAktiv(condition);
    
      try {
         const {data: response} = await axios(Global.apiBaseURL + 'textRatingUpdate/'+textRateId, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization" :  sessionStorage.getItem("accessToken"),
          },
          data: {
            enabled : condition == true ? 1 : 0
          }
        });
        if (response !== undefined){
          if(response.status === 200) {
            if (response.data !== undefined) {
              setSnackOpen(!snackopen);
            } 
            else {
              alert("Hiba a lekérdezés során!");
            }
          }
        } 
      }
      catch (e) {
        console.log(e);
        toast.error(e, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    };

    return (
      <div>
        <Button variant="outlined" onClick={handleModalOpen}>
          Módosítás
        </Button>
        <Dialog
          fullScreen
          scroll="body"
          open={props.openModal}
          onClose={handleModalClose}
          TransitionComponent={Transition}
          style={{zIndex : "9000"}}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleModalClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 5, flex:5 }} variant="h6" component="div">
                 {props.selectRow.workplace.company.name} 
                 <Badge badgeContent={props.selectRow.textRatings.length} color="primary" sx={{ ml: 5}}>
                   <ChatBubbleIcon color="action" />
                 </Badge>
              </Typography>
              <Button autoFocus color="inherit" onClick={handleModalClose}>
                Kilépés
              </Button>
            </Toolbar>
          </AppBar>


         {/*
          {props.selectRow.textRatings.map((textRating,i)=>{
             return <li key={i}>{textRating.modified}</li>
          })}
          */}

          {props.selectRow.textRatings.map((textRating,i)=>{
            return( 
            <Card   sx={{ border: 0.2, p: 1, maxWidth: 1000 , borderRadius: 4, boxShadow: 8,  margin: 2, align: "justify"}}>
            <CardContent>
                <Chip 
                  sx={{mb: 1 }} 
                  icon={textRating.initiator == 'U' ? <AccountCircleIcon /> : <ApartmentRoundedIcon />}
                  label={textRating.initiator == 'U' ? props.selectRow.workplace.user.email : props.selectRow.workplace.company.name} 
                 />
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom >
                {format(new Date(textRating.modified),'yyyy-MM-dd HH:mm')}
                <br/>
                {formatDistanceToNow(
                    new Date(textRating.modified),
                    {includeSeconds: true, locale: hu}
                    )}
                </Typography>
                <Typography variant="h4" sx={{ fontSize: 16, fontWeight: 'bold' }} color="text.secondary" gutterBottom >
                  {props.selectRow.workplace.user.lastName} {props.selectRow.workplace.user.firstName}
                </Typography>
                <br />
                <Typography variant="body2" component="div" sx={{overflow : "hidden", wordWrap : "anywhere"}}>
                {textRating.textRating}
                <br />
                {textRating.textRatingId}
                <br />
                {textRating.enabled}
                </Typography>
            </CardContent>
            <CardActions>
                <FormControlLabel control={<Switch 
                  defaultChecked={textRating.enabled=="true"}
                  onChange={(e) => handleVisibilityChange(e,textRating.textRatingId)}
                  />} label="Látható" />
            </CardActions>
          </Card>
            )
          })}
          <Snackbar open={snackopen} autoHideDuration={1500} onClose={handleSnackClose} TransitionComponent="Down" anchorOrigin={{vertical : 'bottom', horizontal : 'right'  }}>
            { snackactive && 
              <Alert onClose={handleSnackClose} severity="success" sx={{width: '100%'}}>
                Látható!      
              </Alert>
              ||
              <Alert onClose={handleSnackClose} severity="error" sx={{width: '100%' }}>
                Elrejtve!         
              </Alert>  
            }
          </Snackbar> 
        </Dialog>
      </div>
    );
}


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
  });

export default ModerationDialog;
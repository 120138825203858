import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { format, formatDistanceToNow } from 'date-fns';
import { hu } from 'date-fns/locale';
import Divider from '@mui/material/Divider';

import Switch from '@mui/material/Switch';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import WorkIcon from '@mui/icons-material/Work';
import HomeIcon from '@mui/icons-material/Home';
import PhoneIcon from '@mui/icons-material/PhoneAndroid';
import EmailIcon from '@mui/icons-material/AlternateEmail';
import BankAccountIcon from '@mui/icons-material/AccountBalance';
import ContyIcon from '@mui/icons-material/Room';
import TaxIcon from '@mui/icons-material/Sell';
import RestoreIcon from '@mui/icons-material/Restore';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { apiBaseURL } from '../Global';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import * as Global from '../Global';
import axios from 'axios';
import { toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';


const CompanyDialog = (props) => {
    const [snackopen,     setSnackOpen]      = React.useState(false);
    const [snackactive,   setSnackAktiv]     = React.useState(true);
    const [taxnumber,     setTaxNumber]      = React.useState(props.selectRow.taxNumber);
    const [taxnumberInfo, setTaxNumberInfo]  = React.useState('');

    const handleModalOpen = () => {
      props.setOpenModal(true);
    };
    
    const handleModalClose = () => {
      props.setOpenModal(false);
    };

    const handleSnackClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setSnackOpen(false);
    };

    const handleCompanyVisibilityChange = (event,id) => {
      companyVisibilityChange(event.target.checked,id);
    };


    const companyVisibilityChange = async(condition,companyId) => {
      //alert(condition + '   ID:'+companyId);
      setSnackOpen(true);
      setSnackAktiv(condition);
    
      try {
         const {data: response} = await axios(Global.apiBaseURL + 'companyUpdate/'+companyId, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization" :  sessionStorage.getItem("accessToken"),
          },
          data: {
            enabled : condition == true ? 1 : 0
          }
        });
        if (response !== undefined){
          if(response.status === 200) {
            if (response.data !== undefined) {
              setSnackOpen(!snackopen);
            } 
            else {
              alert("Hiba a lekérdezés során!");
            }
          }
        } 
      }
      catch (e) {
        toast.error(e, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    };



    const companyTaxnumberChange = async(companyId) => {
      alert(taxnumber + '   ID:'+companyId);
      //setSnackOpen(true);
      //setSnackAktiv(condition);
    
      try {
         const {data: response} = await axios(Global.apiBaseURL + 'companyUpdate/'+companyId, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization" :  sessionStorage.getItem("accessToken"),
          },
          data: {
            taxNumber : taxnumber
          }
        });
        if (response !== undefined){
          if(response.status === 200) {
            if (response.data !== undefined) {
              setTaxNumberInfo("Mentve: "+taxnumber);
            } 
            else {
              setTaxNumberInfo(response.error.message);
            }
          }
        } 
      }
      catch (e) {
        toast.error(e, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    };


    function validateTaxNumber() {
      return (taxnumber=='NA' || (( taxnumber.slice(8,9)=='-') && ( taxnumber.slice(10,11)=='-') && (taxnumber.length==13)));  //10904589-2-13 
    }

    return (
      <div>

        <Button variant="contained" startIcon={<EditIcon />} onClick={handleModalOpen} sx={{ m : 1}}>
          Módosítás
        </Button>

        <Dialog
          fullScreen
          scroll="body"
          open={props.openModal}
          onClose={handleModalClose}
          TransitionComponent={Transition}
          style={{zIndex : "9000"}}
        >
          <AppBar sx={{ position: 'sticky'}}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleModalClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 5, flex:5 }} variant="h6" component="div">
                {props.selectRow.name}
              </Typography>
              <Typography sx={{mr: 14, fontSize: 14, textAlign : "center" }} color="text.secondary" gutterBottom >
                {format(new Date(props.selectRow.created),'yyyy-MM-dd HH:mm')}
                <br/>
                {formatDistanceToNow(
                    new Date(props.selectRow.created),
                    {includeSeconds: true, locale: hu}
                    )}
                </Typography>
              <Button autoFocus color="inherit" onClick={handleModalClose}>
                Kilépés
              </Button>
            </Toolbar>
          </AppBar>  
          <List sx={{ pl: "50px", width: '100%', maxWidth: 900, bgcolor: 'background.paper' }}>
            <ListItem>
                <ListItemAvatar>
                <Avatar
              alt={props.selectRow.name}
              src={apiBaseURL+props.selectRow.profilePicture}
             
            /> 
                </ListItemAvatar>
                <ListItemText primary="Cégnév" secondary={props.selectRow.name} />
            </ListItem>
            <Divider component="li" />
            <ListItem>
                <ListItemAvatar>
                <Avatar>
                    <HomeIcon />
                </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Cím"    
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                            {props.selectRow.zipCode} {props.selectRow.city} {","} {props.selectRow.streetName} {props.selectRow.streetType} {props.selectRow.houseNumber}   
                            </Typography>
                        </React.Fragment>
                    } />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                <Avatar>
                    <ContyIcon />
                </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Megye" secondary={props.selectRow.county} />
            </ListItem>
            <Divider component="li" />
            <ListItem>
                <ListItemAvatar>
                <Avatar>
                    <PhoneIcon />
                </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Telefon" secondary={props.selectRow.telephone} />
            </ListItem>
            <Divider component="li" />
            <ListItem>
                <ListItemAvatar>
                <Avatar>
                    <EmailIcon />
                </Avatar>
                </ListItemAvatar>
                <ListItemText primary="E-mail" secondary={props.selectRow.email} />
            </ListItem>
            <Divider component="li" />
            <ListItem>
                <ListItemAvatar>
                <Avatar>
                    <TaxIcon />
                </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Adószám" secondary={props.selectRow.taxNumber} />
                <TextField
                  error = {!validateTaxNumber()}
                  id="taxnumber"
                  label="Adószám"
                  defaultValue={props.selectRow.taxNumber}
                  sx={{ mr: 2}}
                  onChange={(newValue) => {setTaxNumber(newValue.target.value);}}
                  helperText={taxnumberInfo}

                />
                <Button variant="contained" 
                  endIcon={<SendIcon/>} 
                  disabled={!validateTaxNumber()}
                  onClick={() => companyTaxnumberChange(props.selectRow.companyId)}
                >
                  Mentés
                </Button>
            </ListItem>
            <Divider component="li" />
            <ListItem>
                <ListItemAvatar>
                <Avatar>
                    <BankAccountIcon />
                </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Bankszámlaszám" secondary={props.selectRow.bankAccountNumber} />
            </ListItem>
            <Divider component="li" />
            <ListItem>
                <ListItemAvatar>
                <Avatar>
                    <RestoreIcon />
                </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Módosítva:" secondary={formatDistanceToNow(new Date(props.selectRow.created),{includeSeconds: true, locale: hu})}/>
            </ListItem>
            <Divider component="li" />
            <ListItem>
                <ListItemAvatar>
                <Avatar>
                    <WorkIcon />
                </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Foglalkozási kör" secondary={props.selectRow.occupationalScope} />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                <Avatar>
                    <WorkIcon />
                </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Leírás" secondary={props.selectRow.description} />
            </ListItem>
            <Divider component="li" />
            <ListItem>
                <ListItemIcon>
                <VisibilityIcon  />
                </ListItemIcon>
                <ListItemText id="switch-list-label-enabled" primary="Aktív" />
                <Switch
                  defaultChecked={props.selectRow.enabled=="true"}
                  onChange={(e) => handleCompanyVisibilityChange(e,props.selectRow.companyId)}
                />
            </ListItem>
           </List> 

           <Snackbar open={snackopen} autoHideDuration={1500} onClose={handleSnackClose} TransitionComponent="Down" anchorOrigin={{vertical : 'bottom', horizontal : 'right'  }}>
            { snackactive && 
              <Alert onClose={handleSnackClose} severity="success" sx={{width: '100%'}}>
                {props.selectRow.name} aktív!      
              </Alert>
              ||
              <Alert onClose={handleSnackClose} severity="error" sx={{width: '100%' }}>
                {props.selectRow.name} inaktív!         
              </Alert>  
            }
          </Snackbar>  
        </Dialog>
      </div>
    );
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
  });

export default CompanyDialog;
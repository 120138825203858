import { useEffect, useState, useImperativeHandle , forwardRef } from "react";
import AppBar from "../components/Appbar";
import Home from "../pages/Home";
import Sidebar from "./Sidebar";
import Users from "../pages/Users";
import Companies from "../pages/Companies";
import { Alert } from "@mui/material";


const sidebarWidth = 250;

export const Layout = forwardRef(({children},ref) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);  // isAuthenticated true or false
    const [sidebar, setSidebar]       = useState(false);
    const [appbar, setAppbar]         = useState(false);
    const [window, setWindow]         = useState(false);
    const [windowText, setWindowText] = useState('');


    useImperativeHandle(ref, () => {
      return {
        setIsAuthenticated: setIsAuthenticated
      }
    });


    useEffect(() => {
        const token = sessionStorage.getItem("accessToken"); //localStorage.getItem("accessToken");
        if (token) {
            setIsAuthenticated(true);   
        } 
        else { 
          console.log("Nincs bejelentkezve!");
          setIsAuthenticated(false);    
        }
        if (sessionStorage.getItem("accessToken")==='null') {setIsAuthenticated(false)}
    }, [sidebar]);

    return (
        <div>
            { isAuthenticated ? <AppBar  setSidebar={setSidebar} />: null } {/*isAuthenticated*/}
            { isAuthenticated ? <Sidebar open={sidebar} sidebarWidth={sidebarWidth} setSidebar={setSidebar} setWindow={setWindow} setWindowText={setWindowText} />: null }
            {children}
        </div>        
    );
  }
)
import React, { Children } from 'react';

import { Divider, Drawer, List, ListItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import BusinessIcon from '@mui/icons-material/Business';
import TimelineIcon from '@mui/icons-material/Timeline';
import MessageIcon from '@material-ui/icons/Message';
import DashboardIcon from '@material-ui/icons/Dashboard';
import GroupIcon from '@material-ui/icons/Group';
import GradeIcon from '@material-ui/icons/Grade';
import NotificationsIcon from '@material-ui/icons/Notifications';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import SettingsIcon from '@material-ui/icons/Settings';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import BlockIcon from '@material-ui/icons/Block';


import * as Rights from '../Rights';
import { SettingsSharp } from '@mui/icons-material';
import { useHistory } from "react-router-dom";
import { Layout } from './Layout';

const Sidebar = (props) => {
  let history = useHistory();
  const menuItemSelect = (id) => {
    switch (id) {
      case 'dashboard': 
      {
        history.push("/dashboard");
        props.setSidebar(false);
        break;
      }; 
      case 'exit': 
      {
        history.push("/login");
        sessionStorage.setItem("accessToken", null);
        props.setSidebar(false);
        break;
      }; 
      case 'users': 
      {
        history.push("/users");
        props.setSidebar(false);
        break;
      }; 
      case 'companies': 
      {
        history.push("/companies");
        props.setSidebar(false);
        break;
      }; 
      case 'matches': 
      {
        history.push("/matches");
        props.setSidebar(false);
        break;
      }; 

      case 'advertisements':
      {
        history.push("/advertisements");
        props.setSidebar(false);
        break;
      }


      case 'moderation':
      {
        history.push("/moderation");
        props.setSidebar(false);
        break;
      }

      case 'adminusers':
      {       
        history.push("/adminusers");
        props.setSidebar(false);
        break;
      }

      case 'infobubble':
      {       
        history.push("/adminusers");
        props.setSidebar(false);
        break;
      }

      case 'headhunting':
      {       
        history.push("/headhunting");
        props.setSidebar(false);
        break;
      }

      default:
      {
        alert("Nincs ilyen menüpont! :(");
        break;
      }
    }
  }

  return (
    <Drawer
      sx={{
        width: props.sidebarWidth ?? 240,
        maxWidth: '100vw',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: props.sidebarWidth ?? 240,
          maxWidth: '100vw',
          boxSizing: 'border-box',
          zIndex : "9000",
        },
      }}
      variant="persistent"
      anchor="left"
      open={props.open ?? false}
    >
      <div>
        <IconButton onClick={() => props.setSidebar(false)} sx={{ m: 0 }}>
          <ChevronLeftIcon sx={{ fontSize: 40 }} />
        </IconButton>
      </div>
      
      <Divider />

      <List>
        <ListItem button onClick={ () => menuItemSelect('users') }>
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary={'Felhasználók'} />
        </ListItem>

        <ListItem button onClick={ () => menuItemSelect('companies') }>
          <ListItemIcon>

            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary={'Cégek'} />
        </ListItem>
        <ListItem button onClick={ () => menuItemSelect('advertisements') }>
          <ListItemIcon>
            <RemoveRedEyeIcon />
          </ListItemIcon>
          <ListItemText primary={'Hirdetések'} />
        </ListItem>

        <ListItem button onClick={ () => menuItemSelect('moderation') }>
          <ListItemIcon>
            <BlockIcon />
          </ListItemIcon>
          <ListItemText primary={'Moderálás'} />
        </ListItem>

        {Rights.SeeStatistics &&
          <ListItem button onClick={ () => menuItemSelect('') }>
            <ListItemIcon>
              <TimelineIcon />
            </ListItemIcon>
            <ListItemText primary={'Kifizetések'} />
          </ListItem>
        }

        <ListItem button onClick={ () => menuItemSelect('headhunting') }>
          <ListItemIcon>
            <SentimentVerySatisfiedIcon />
          </ListItemIcon>
          <ListItemText primary={'Fejvadászat'} />
        </ListItem>

        <ListItem button onClick={ () => menuItemSelect('adminusers') }>
          <ListItemIcon>
            <SettingsIcon/>
          </ListItemIcon>
          <ListItemText primary={'Admin felhasználók'} />
        </ListItem>
      </List>
        <Divider />
      <List>
        {Rights.SeeSettings &&
          <ListItem button onClick={ () => menuItemSelect('settings') }>
            <ListItemIcon>
              <SettingsSharp/>
            </ListItemIcon>
            <ListItemText primary={'Beállítások'} />
          </ListItem>
        }

        <ListItem button onClick={ () => menuItemSelect('exit') }>  
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={'Kijelentkezés'} />
        </ListItem>
      </List>
      <div style={{flex: 1}}></div>
      <img src="refbooklogo.svg"  
       style={{width: "60%" , margin: "42px", display: "block"}} 
       onClick={ () => menuItemSelect('dashboard') }
      />
    </Drawer>
  )
};

export default Sidebar;

import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import * as Global from '../Global';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import GroupIcon from '@material-ui/icons/Group';
import CompanyDialog from './CompanyDialog';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { format, formatDistanceToNow } from 'date-fns';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import Paper from '@mui/material/Paper';



function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) =>  descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Cégnév',
  },
  {
    id: 'city',
    numeric: false,
    disablePadding: false,
    label: 'Cím',
  },
  {
    id: 'occupationalScope',
    numeric: false,
    disablePadding: false,
    label: 'Foglalkozási kör',
  },
  {
    id: 'telephone',
    numeric: false,
    disablePadding: false,
    label: 'Telefon',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'E-mail',
  },
  {
    id: 'taxNumber',
    numeric: false,
    disablePadding: false,
    label: 'Adószám',
  },
  {
    id: 'bankAccountNumber',
    numeric: false,
    disablePadding: false,
    label: 'Bankszámlaszám',
  },
  {
    id: 'created',
    numeric: false,
    disablePadding: false,
    label: 'Regisztráció',
  },
  {
    id: 'enabled',
    numeric: false,
    disablePadding: false,
    label: 'Státusz',
  },

];



function CustomTableHead(props) {
  const { classes } = props;
  const [selectedID, setSelectedID] = React.useState(null);
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{position:'sticky', top:157, zIndex: 1000}}>
      <TableRow>
        <TableCell 
          padding="space"
          scope="row"   
        >         
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

CustomTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function CompanyRow(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({userId:"-500"});

  
  const rowClick = (event, selectRow) => {
    setSelectedRow(selectRow);
  };

  return (
    <React.Fragment>
      <TableRow 
        sx={{ '& > *': { borderBottom: 'unset' } }}
        onClick={(event) => rowClick(event, row)}     
      >
        <TableCell>
          <IconButton
             aria-label="expand row" 
             size="small" 
             onClick={() => setOpen(!open)}>
             {open ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="left">{row.zipCode}  {row.city}, {row.streetName} {row.streetType} {row.houseNumber} </TableCell>
        <TableCell align="left">{row.occupationalScope}</TableCell>
        <TableCell align="left">{row.telephone}</TableCell>
        <TableCell align="left">{row.email}</TableCell>
        <TableCell align="left">{row.taxNumber}</TableCell>
        <TableCell align="left">{row.bankAccountNumber}</TableCell>
        <TableCell align="left">{format(new Date(row.created),'yyyy-MM-dd HH:mm')}</TableCell>
        <TableCell align="left">
          <Chip     
            color={row.enabled == 'true' ? "success" : "error"}   
            label={row.enabled == 'true' ? "Aktív" : "Inaktív"} 
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Chip icon={<GroupIcon/>} label="Felhasználók"/>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">E-mail</TableCell>
                    <TableCell align="left">Regisztráció</TableCell>
                    <TableCell align="left">Utolsó módosítás</TableCell>
                    <TableCell align="left">Státusz</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.companyUsers.map((companyUsersRow) => (
                    <TableRow key={companyUsersRow.companyUserId}>
                      <TableCell component="th" scope="row">
                        {companyUsersRow.email}
                      </TableCell>
                      <TableCell align="left">{format(new Date(companyUsersRow.created),'yyyy-MM-dd HH:mm')}</TableCell>
                      <TableCell align="left">{format(new Date(companyUsersRow.modified),'yyyy-MM-dd HH:mm')}</TableCell>
                      <TableCell align="left">{companyUsersRow.enabled ? "Aktív"  : "Törölve"}</TableCell>
                    </TableRow>
                  ))}
                  { (row.companyId>0) &&
                      <CompanyDialog openModal={openModal} setOpenModal={setOpenModal} selectRow={row}/>  
                  }
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function Companies() {
  const [rows, setTableData]             = React.useState([]);
  //filter
  const [company, setCompany]            = React.useState('');
  const [taxnumber, setTaxnumber]        = React.useState('');
  const [county, setCounty]              = React.useState('');
  const [city, setCity]                  = React.useState('');
  const [enabled, setEnabled]            = React.useState('');
  //Pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [order, setOrder] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('');
 
 
  const onSearchClick =async()=> {
    var filter = {};   
    var pagination = {};

    pagination.currentPage = 1;
    pagination.dataPerPage = 250000;

    if (company!=='') {
      //filter["name"] = [];
      filter.name = company;
    }
    if (taxnumber!=='') {
      filter.taxNumber = taxnumber;
    }
    if (county!=='') {
      filter["county"] = [];
      filter.county = county;
    }
    if (city!=='') {
      filter["city"] = [];
      filter.city = city;
    }
    if (enabled!=='') {
      filter["enabled"] = [];
      if (enabled==="true") {
        filter.enabled = 1;
      }
      else {
        filter.enabled = 0;
      }
    }
      
    try {
      const {data: response} = await axios(Global.apiBaseURL + 'companyUsersByCompanies', {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type"  : "application/json",
          "Authorization" :  sessionStorage.getItem("accessToken"),
  
        },
        data: {
          pagination,
          filter
        }
      });
      if (response !== undefined){
        if(response.status === 200) {
          if (response.data !== undefined) {
            setTableData(response.data);
          } 
          else {
            alert("Hiba a lekérdezés során!");
          }
        } 
        else {
          alert(response.error.message);
        } 
      }
    }
    catch (e) {
      toast.error(e, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  return (
    <Box sx={{ width: '100%', flexGrow: 1 }}>
      <Paper sx={{ width: '100%', mb: 2}}>
        <div style={{position : "sticky" , top: "80px", backgroundColor : "white", padding : "10px", borderBottom : "1px solid black" ,zIndex : "1000"}}>
          <Grid container spacing={2}>
            <Grid item md={2} sm={6} xs={12}>
            <FormControl fullWidth>
                <TextField 
                id="company" 
                label='Cégnév' 
                onChange={(newValue) => { setCompany(newValue.target.value); }}
                >
                </TextField> 
              </FormControl>
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <FormControl fullWidth>
                <TextField 
                id="taxnumber" 
                label='Adószám' 
                onChange={(newValue) => { setTaxnumber(newValue.target.value); }}
                >
                </TextField> 
              </FormControl>
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="megye-select-label">Megye</InputLabel>
                <Select
                  labelId="county-select-label"
                  id="county-select"
                  label="Megye"  
                  onChange={(newValue) => {
                    setCounty(newValue.target.value);
                  }} 
                >
                {Global.Counties?.map(CountyItem => {
                  return (
                    <MenuItem 
                      key={CountyItem.value}
                      value={CountyItem.value}
                      style={{display: 'flex',}}
                    >
                      {CountyItem.label ?? CountyItem.value}
                    </MenuItem>
                  );
                  })} 
                </Select>
              </FormControl>  
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <TextField 
                id="searchcolumn" 
                label='Város' 
                style={{width : "100%"}}
                onChange={(newValue) => { setCity(newValue.target.value); }}
                >
              </TextField> 
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="oszlop-select-label">Státusz</InputLabel>
                <Select
                  labelId="filter-select-label"
                  id="filter-select"
                  label="Státusz"
                  onChange={(newValue) => {setEnabled(newValue.target.value);}}
                  >
                    {Global.StatusConditions?.map(StatusItem => {
                    return (
                      <MenuItem key={StatusItem.value} value={StatusItem.value} name={StatusItem.label}>
                        {StatusItem.label ?? StatusItem.value}
                      </MenuItem>
                    );
                    })}      
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <Button 
                  variant="contained" 
                  aria-label="search"
                  startIcon={<SearchIcon fontSize="large" />}
                  size="medium"
                  onClick={onSearchClick}
                  style={{width : "100%", height :"100%"}}
                  >
                  Keresés
              </Button>
            </Grid>
          </Grid>
        </div>


        <TableContainer sx={{ maxHeight: "50vh" }} style={{overflowX: 'none'}} id="companies-tablecontainer">
          <Table 
            stickyHeader 
            aaria-label="collapsible table"
          >
            <CustomTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}     
            />       
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => { 
              return(
                <CompanyRow key={index} row={row}/> 
              )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}





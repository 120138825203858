import * as React from 'react';
import { useEffect, useState } from "react";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import 'react-toastify/dist/ReactToastify.css';
import * as Global from '../Global';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { sha512 } from 'js-sha512';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { styled } from '@mui/material/styles';

const FormItem = styled(Paper)(({ theme }) => ({
   ...theme.typography.body2,
   padding: theme.spacing(2),
   textAlign: 'center',
   alignItems: 'center',
   boxShadow: 'none'
}));


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



function UserAdminDialog (props) {
  const [snackopen, setSnackOpen] = React.useState(false);
  const [fullname, setFullname]   = useState(props.selectRow.fullName);
  const [email, setEmail]         = useState(props.selectRow.email ?? "");
  const [password,      setPassword]      = useState("");
  const [passwordagain, setPasswordAgain] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const getUpdateUser =async()=> {
    if(password !== passwordagain){
      toast.error('A megadott jelszavak nem egyeznek meg');
      return;
    }

    try {
      const {data: response} = await axios(Global.apiBaseURL + 'adminUserUpdate/'+props.selectRow.adminUserId, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization" :  sessionStorage.getItem("accessToken"),
        },
        data: {
          fullName       : fullname,
          email          : email,
          password       : sha512(password),
        }
      });

      if (response !== undefined){
        if(response.error){
          toast.error(response.error.message);
        }
        else{
          toast.success('Az admin sikeresen frissítve');
          handleModalClose();
        }
      }
    }
    catch (e) {
      toast.error(e, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  }



  function validatePassword() {
    return (password==passwordagain && password.length>5); 
  }

  const handleModalOpen = () => {
    props.setOpenModal(true);
    setFullname(props.selectRow.fullName);
    setEmail(props.selectRow.email);
  };
  
  const handleModalClose = () => {
    props.setOpenModal(false);
    setSnackOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleModalOpen}>
        Felhasználó módosítása: {props.selectRow.fullName}
      </Button>
      <Dialog
        open={props.openModal}
        onClose={handleModalClose}
        TransitionComponent={Transition}
        style={{zIndex : "9000"}}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleModalClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {props.selectRow.fullName}
            </Typography>
            <Button autoFocus color="inherit" onClick={getUpdateUser}>
              Mentés
            </Button>
          </Toolbar>
        </AppBar>

        <Grid container >
          <Grid item xs={12} style={{flexDirection: "row", alignItems: "center", justifyContent: "center", display: "flex", padding: "15px"}}>
            <Avatar
              alt={props.selectRow.fullName}
              variant="round"
              src={Global.apiBaseURL+props.selectRow.profilePicture}
              sx={{ width: 160, height: 160 }}
              // style={{align: "center",  }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormItem>
              <TextField 
                id="searchadminfullnev" 
                label='Teljes név' 
                //value={props.selectRow.fullName}
                value={fullname}
                onChange={(newValue) => {
                  setFullname(newValue.target.value);  
                }} 
                style={{width : "320px"}}>
              </TextField> 
            </FormItem>
          </Grid>
          <Grid item xs={12}>
            <FormItem>
              <TextField 
                id="email" 
                label='E-mail cím' 
                value={email}
                onChange={(newValue) => {
                  setEmail(newValue.target.value);  
                }} 
              //
                error={!email.match(
                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )
              }
                helperText={!email.match("@") ? 'E-mail cím nem megfelelő' : ' '}
                style={{width : "320px"}}>
              </TextField> 
            </FormItem>
          </Grid>
          <Grid item xs={12}>
            <FormItem>
              <TextField 
                  id="emailagain" 
                  label='Jelszó' 
                  type='password'
                  onChange={(newValue) => {setPassword(newValue.target.value);}}
                  helperText="Kérem adja meg az új jelszavát"
                  style={{width : "320px"}}>
              </TextField> 
            </FormItem>
          </Grid>
          <Grid item xs={12}>
            <FormItem>
              <TextField 
                  id="searchfullnev" 
                  label='Jelszó újra' 
                  type='password'
                  onChange={(newValue) => {setPasswordAgain(newValue.target.value);}}
                  error = {!validatePassword()}  
                  helperText={password !== passwordagain ? 'Jelszó nem megfelelő!' : ' '}
                  
                  style={{width : "320px"}}>
              </TextField> 
            </FormItem>
          </Grid>
          {/* <Grid item xs={12}>
            <FormItem>
              <FormControlLabel control={<Switch defaultChecked />} label="Aktív" />
              <Button
                variant="contained"
                component="file"
                disabled={false}
                style={{maxWidth: 250, alignSelf: 'center'}}
                >
                Kép feltöltése
                <input
                  type="file"
                  hidden
                  accept=".jpg"
                  onChange={(event) => {setSelectedFile(event.target.files[0])}}
                  />
              </Button>              
            </FormItem>
          </Grid> */}
        </Grid>
      </Dialog>

      <Snackbar open={snackopen} autoHideDuration={4000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
           Módosítva!
        </Alert>
      </Snackbar>            
    </div>
  );
}

export default UserAdminDialog;
import * as React from 'react';
import { useEffect, useState } from "react";
import Snackbar from '@mui/material/Snackbar';
import * as Global from '../Global';

import Typography from '@mui/material/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';


import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Alert from '@mui/material/Alert';

import axios from 'axios';

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { format, formatDistanceToNow } from 'date-fns';
import { hu } from 'date-fns/locale';

import PlaceIcon from '@mui/icons-material/Place';
import EuroIcon from '@mui/icons-material/Euro';
import SellIcon from '@mui/icons-material/Sell';
import ReceiptIcon from '@mui/icons-material/Receipt';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AddTaskIcon from '@mui/icons-material/AddTask';
import ShareIcon from '@mui/icons-material/Share';
import EventBusyIcon from '@mui/icons-material/EventBusy';



const cardStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  
});

const AdvertisementDialog = (props) => {
    const classes = cardStyles();
    const [snackopen, setSnackOpen] = React.useState(false);
    const [aktiv, setAktiv]      = React.useState(props.selectRow.enabled === 'true' ? true : false);
    const [fizetve, setFizetve]  = React.useState(props.selectRow.paid === 'true' ? true : false);
    const [snackactive, setSnackAktiv]      = React.useState(props.selectRow.enabled === 'true' ? true : false);
   
    useEffect(()=>{
      setAktiv(props.selectRow.enabled === 'true' ? true : false);
      setFizetve(props.selectRow.paid === 'true' ? true : false);
    });

    const switchAktivChange = async(condition) => {
      try {
         const {data: response} = await axios(Global.apiBaseURL + 'jobUpdate/'+props.selectRow.advertisementId, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization" :  sessionStorage.getItem("accessToken"),
          },
          data: {
            enabled : condition == true ? 1 : 0
          }
        });
        if (response !== undefined){
          if(response.status === 200) {
            if (response.data !== undefined) {
    
              setSnackOpen(!snackopen);
    
            } 
            else {
              alert("Hiba a lekérdezés során!");
            }
          }
        }
      }
      catch (e) {
        toast.error(e, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    };


    const switchFizetveChange = async(condition) => {
      try {
         const {data: response} = await axios(Global.apiBaseURL + 'jobUpdate/'+props.selectRow.advertisementId, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization" :  sessionStorage.getItem("accessToken"),
          },
          data: {
            paid : condition == true ? 1 : 0
          }
        });
        if (response !== undefined){
          if(response.status === 200) {
            if (response.data !== undefined) {
              setSnackOpen(!snackopen);
            } 
            else {
              alert("Hiba a lekérdezés során!");
            }
          }
        }
      }
      catch (e) {
        toast.error(e, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    };

    const handleAktivChange = (event) => {
      setAktiv(event.target.checked);
      setSnackAktiv(event.target.checked);
      switchAktivChange(event.target.checked);
    };

    const handleFizetveChange = (event) => {
      setFizetve(event.target.checked);
      switchFizetveChange(event.target.checked);
    };


    const handleModalOpen = () => {
      props.setOpenModal(true);
    };
  
    const handleModalClose = () => {
      props.setOpenModal(false);
    };

    const handleSnackClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setSnackOpen(false);
    };

    return (
      <div>
        <Button variant="outlined" onClick={handleModalOpen}>
          Módosítás
        </Button>
        <Dialog
          fullScreen
          scroll="body"
          open={props.openModal}
          onClose={handleModalClose}
          TransitionComponent={Transition}
          style={{zIndex : "9000"}}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleModalClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                 {props.selectRow.advertisementTitle} 
              </Typography>
              <Button autoFocus color="inherit" onClick={handleModalClose}>
                Kilépés
              </Button>
            </Toolbar>
          </AppBar>

          

          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                {props.selectRow.title}
              </Typography>
              <Typography className={classes.title} color="textSecondary">
                {props.selectRow.created.slice(0,10)}  {props.selectRow.created.slice(11,16)} 
                <br />
                {formatDistanceToNow(
                    new Date(props.selectRow.created),
                    {includeSeconds: true, locale: hu}
                    )}
              </Typography> 
              <br/>
              <List sx={{ pl: "50px", width: '100%', maxWidth: 900, bgcolor: 'background.paper' }}>
                <ListItem>
                    <ListItemAvatar>
                    <Avatar>
                        <PlaceIcon />
                    </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Munkavégzés helye" secondary={props.selectRow.city} />
                </ListItem>
                <Divider component="li" />
                <ListItem>
                    <ListItemAvatar>
                    <Avatar>
                        <EuroIcon />
                    </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Várható bérezés" secondary={props.selectRow.salary} />
                </ListItem>
                <Divider component="li" />

                <ListItem>
                    <ListItemAvatar>
                    <Avatar>
                        <AddTaskIcon />
                    </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Amit kínálunk" secondary={props.selectRow.description} />
                </ListItem>
                <Divider component="li" />
                <ListItem>
                    <ListItemAvatar>
                    <Avatar>
                        <ReceiptIcon />
                    </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Ajánlatunk" secondary={props.selectRow.offer} />
                </ListItem>
                <Divider component="li" />
                <ListItem>
                    <ListItemAvatar>
                    <Avatar>
                        <EngineeringIcon />
                    </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Elvárások" secondary={props.selectRow.expectation} />
                </ListItem>
                <Divider component="li" />
                <ListItem>
                    <ListItemAvatar>
                    <Avatar>
                        <SellIcon />
                    </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Cimkék" secondary={props.selectRow.positionTags} />
                </ListItem>
                <Divider component="li" />
                <ListItem>
                    <ListItemAvatar>
                    <Avatar>
                        <ShareIcon />
                    </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Állapot" 
                     secondary={
                      <React.Fragment>
                          <Typography
                              sx={{ display: 'inline' }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                          >
                          {"Megosztás:"} {props.selectRow.numberOfShares} <br/> {"Jelentkezők:"} {props.selectRow.numberOfApplicants}  
                          </Typography>
                      </React.Fragment>
                  } />
                </ListItem>
                <Divider component="li" />
                <ListItem>
                    <ListItemAvatar>
                    <Avatar>
                        <EventBusyIcon />
                    </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Lejárat" secondary={format(new Date(props.selectRow.expiry),'yyyy-MM-dd HH:mm')} />
                </ListItem>
                <Divider component="li" />
                <ListItem>
                  <ListItemIcon>
                  <VisibilityIcon  />
                  </ListItemIcon>
                  <ListItemText id="switch-list-label-enabled" primary="Aktív" />
                  <Switch
                    defaultChecked={props.selectRow.enabled=="true"}
                    //onChange={(e) => handleCompanyVisibilityChange(e,props.selectRow.companyId)}
                    onChange={handleAktivChange}  
                  />
                </ListItem>
                <Divider component="li" />
                <ListItem>
                  <ListItemIcon>
                  <VisibilityIcon  />
                  </ListItemIcon>
                  <ListItemText id="switch-list-label-enabled" primary="Fizetve" />
                  <Switch
                    defaultChecked={props.selectRow.paid=="true"}
                    //onChange={(e) => handleCompanyVisibilityChange(e,props.selectRow.companyId)}
                    onChange={handleFizetveChange} 
                  />
                </ListItem>
            </List>
            </CardContent>
          </Card> 
        </Dialog>
        <Snackbar open={snackopen} autoHideDuration={2500} onClose={handleSnackClose}>
          { snackactive && 
            <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
               A hirdetés aktív!      
            </Alert>
            ||
            <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
               A hirdetés inaktív!         
            </Alert>  
          }
        </Snackbar> 
      </div>
    );
  }

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  export default AdvertisementDialog;
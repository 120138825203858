import React, { useRef, useState } from 'react';
import './App.css';
import Login from './pages/Login';
import 'react-toastify/dist/ReactToastify.min.css';

import { Layout } from './components/Layout';
import Home from './pages/Home';
import Users from './pages/Users'; 
import Matches from './pages/Matches';
import Companies from './pages/Companies';
import Advertisements from './pages/Advertisements';
import AdminUsers from './pages/AdminUsers';
import Dashboard from './pages/Dashboard';
import HeadHunting from './pages/HeadHunting';
import Moderation from './pages/Moderation';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes, 
  Link,
} from "react-router-dom";

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const NoMatchPage = () => {
  return (
    <Alert severity="error">
        <AlertTitle>Hiba!</AlertTitle>
         Az oldal <strong>nem található!</strong>
    </Alert>
  );
};

const LogOutPage = () => {
  //session eldobása!
  return (
    <Alert severity="info">
        <AlertTitle>Kijeletkezve</AlertTitle>
         Ön kijelentkezett a rendszerből
    </Alert>
  );
};

function App() { 
  const layoutRef  = useRef(null);
  const setIsAuthenticated = () => {
    layoutRef.current.setIsAuthenticated(true);
  }

  return(
    <Router>
    <Layout ref={layoutRef}>
      <Switch> 
        <Route exact path='/' render={() => <Login setIsAuthenticated={setIsAuthenticated} />} ></Route>
        <Route path="/login" render={() => <Login setIsAuthenticated={setIsAuthenticated} />} ></Route>
        <Route path="/users" component={Users}></Route>
        <Route path="/home" component={Home}></Route>
        <Route path="/companies" component={Companies}></Route>
        <Route path="/matches" component={Matches}></Route>
        <Route path="/advertisements" component={Advertisements}></Route>
        <Route path="/dashboard" component={Dashboard}></Route>
        <Route path="/adminusers" component={AdminUsers}></Route>
        <Route path="/headhunting" component={HeadHunting}></Route>
        <Route path="/moderation" component={Moderation}></Route>
        <Route path="/logout" component={LogOutPage}></Route>
        <Route component={NoMatchPage}></Route>                   // Error Page
      </Switch>
    </Layout>
  </Router>
  )
}

export default App;

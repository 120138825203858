import * as React from 'react';
import { useEffect } from "react";
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import { visuallyHidden } from '@mui/utils';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Global from '../Global';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import InfoIcon from '@mui/icons-material/Info';
import Slide from '@mui/material/Slide';
import { DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Button from '@mui/material/Button';
import { format, formatDistanceToNow } from 'date-fns';
import Switch from '@mui/material/Switch';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}



function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function hhtableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


const headhuntingCells = [
    {
      id: 'created',
      numeric: false,
      disablePadding: true,
      label: 'Megosztás ideje',
    },
    {
      id: 'companyCompanyName',
      numeric: false,
      disablePadding: false,
      label: 'Megosztás cég',
    },
    {
      id: 'advertisementId',
      numeric: true,
      disablePadding: false,
      label: 'Hirdetés ID',
    },
    {
      id: 'advertisementTitle',
      numeric: false,
      disablePadding: false,
      label: 'Hirdetés címe',
    },
    {
      id: 'contactUserName',
      numeric: false,
      disablePadding: false,
      label: 'Munkavállaló',
    },
    {
      id: 'bonusSalary',
      numeric: true,
      disablePadding: false,
      label: 'Bónusz fizetés',
    },
    {
      id: 'sharingUserUserName',
      numeric: false,
      disablePadding: false,
      label: 'Megosztó',
    },
    {
      id: 'advertisementBountyHunterFee',
      numeric: true,
      disablePadding: false,
      label: 'Fejvadász díj',
    },
    {
      id: 'sharingUserBankAccountNumber',
      numeric: false,
      disablePadding: false,
      label: 'Bankszámlaszám',
    },
    {
      id: 'workplaceCreated',
      numeric: false,
      disablePadding: false,
      label: 'Dátum',
    },
    {
      id: 'paymentStatus',
      numeric: false,
      disablePadding: false,
      label: 'Fizetve',
    },
];

function HeadHuntingTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  
  return (
    <TableHead>
      <TableRow>
        <TableCell 
          padding="space"
          scope="row"   
        >         
        </TableCell>
        {headhuntingCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

HeadHuntingTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const AdminUsersTableToolbar = (props) => {
  const { numSelected } = props.numSelected;
  const [snackopen, setSnackOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);


  useEffect(() => {
    console.log('openModal: '+openModal);
    if (!openModal) {
      console.log(props.selectRow.paymentStatus);
    }
  }, [openModal]); 


  

  const handleSnackClose = (event, reason) => {
    console.log("handleSnackClose  "+reason);
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };


  return (
    <Toolbar  
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      
      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h5"
        id="tableTitle"
        component="div"
      >
        Fejvadász díj:
      </Typography>
      <IconButton
        aria-label="search"
        color="inherit"
        onClick={()=>{setSnackOpen(!snackopen)} 
      }
        size="large">
        <InfoIcon />
      </IconButton>

      <IconButton
        aria-label="search"
        color="inherit"
        onClick={()=>{setSnackOpen(!snackopen)} 
      }
        size="medium">
      </IconButton>
      {/*  (props.selectRow.bountyHunterLinkId>0) &&  
        <HeadHuntingDialog openModal={openModal} setOpenModal={setOpenModal} selectRow={props.selectRow}/>
       */}
    </Toolbar>
  );
};

AdminUsersTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

//const EnhancedTable = props => {
export default function ModerationTable() {
  const [order, setOrder] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setTableData] = React.useState([]);

  //Filter
  const [fullname,       setfullName]       = React.useState('');
  const [company,        setCompany]        = React.useState('');
  const [startdatevalue, setStartDateValue] = React.useState(new Date(Date.now() - (3600 * 1000 * 24) * Global.DateInterval));

  const [selectedRow, setSelectedRow] = React.useState({bountyHunterLinkId:"-500"});

  const [paymentstatus, setPaymentstatus] = React.useState('U');

  const onSearchClick =async()=> {
    var filter = {};   
    var pagination = {};
    pagination.currentPage = 1;
    pagination.dataPerPage = 250000;
    
    if (fullname!=='') {
      filter.contactUserName = fullname;
    }
    if (company!=='') {
      filter.companyCompanyName = company;
    }
    if (startdatevalue!=='') {
      filter.createdStart = startdatevalue;
    }

    try {
      const {data: response} = await axios(Global.apiBaseURL + 'bountyHunters', {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type"  : "application/json",
          "Authorization" :  sessionStorage.getItem("accessToken"),  
        },
        data: {
          pagination,
          filter
        }
      });

      if (response !== undefined){
        if(response.status === 200) {
          if (response.data !== undefined) {
            setTableData(response.data);
          } 
          else {
            alert("Hiba a lekérdezés során!");
          }
        } 
        else {
          alert(response.error.message);
        } 
      }
    }
    catch (e) {
      console.log(e);
      toast.error(e, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.firstName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const rowClick = (event, selectRow) => {
    setSelectedRow(selectRow);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (bountyHunterLinkId,contactUserId,workplaceId ) => ((selected.indexOf(bountyHunterLinkId) !== -1 ) && (selected.indexOf(contactUserId) !== -1 ) && (selected.indexOf(workplaceId) !== -1 ));
  //const isSelected = (bountyHunterLinkId,contactUserId,workplaceId ) => (selected.indexOf(bountyHunterLinkId) !== -1 );

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

   const paymenthandleSubmit = async (event) => {
      const isChecked = event.target.checked;
      const valueToSend = isChecked ? 'P' : 'U';
      const statement   = isChecked ? 'Fizetve' : 'Nincs fizetve';
      //setPaymentstatus(event.target.value);
      try {
          const {data: response} = await axios(Global.apiBaseURL + 'bountyHunterLinkUpdate/'+selectedRow.bountyHunterLinkId, {
           method: "POST",
           headers: {
             Accept: "application/json",
             "Content-Type": "application/json",
             "Authorization" :  sessionStorage.getItem("accessToken"),
           },
           data: {
              paymentStatus: valueToSend
           }
         });
         if (response !== undefined){
           if(response.status === 200) {
             if (response.data !== undefined) {
              toast.success('Módosítva: '+statement, {
                  position: toast.POSITION.BOTTOM_RIGHT
                });
             } 
             else {
              toast.error('Hiba a módosítás során!', {
                  position: toast.POSITION.BOTTOM_RIGHT
                });
             }
           }
         }
       }
       catch (e) {
         console.log(e);
         toast.error(e, {
           position: toast.POSITION.BOTTOM_RIGHT
         });
       }
       onSearchClick();
    };
    

  return (
    <Box sx={{ width: '100%', flexGrow: 1 }}>
      <Paper sx={{ width: '100%'}}>
        <div style={{position : "sticky" , top: "80px", backgroundColor : "white", padding : "20px", borderBottom : "1px solid black" ,zIndex : "1000"}}>
          <Grid container spacing={2}>
            <Grid item md={2} sm={6} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns} >
                <DatePicker
                label="Dátumtól"
                value={startdatevalue}
                onChange={(newValue) => {
                  setStartDateValue(new Date(newValue.setHours(0,0)));
                }}
                inputFormat="yyyy/MM/dd"
                mask={"20__/__/__"}
                renderInput={(params) => <TextField {...params} style={{width : "100%"}}/>}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item md={4} sm={6} xs={12}>
              <TextField 
                id="searchcompany" 
                label='Cégnév' 
                style={{width : "100%"}}
                onChange={(newValue) => {setCompany(newValue.target.value);}}
                >
              </TextField> 
            </Grid>

            <Grid item md={4} sm={6} xs={12}>
              <TextField 
                id="searchfemployer" 
                label='Munkavállaló' 
                style={{width : "100%"}}
                onChange={(newValue) => {setfullName(newValue.target.value);}}
              >
              </TextField> 
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <Button 
                variant="contained" 
                aria-label="search"
                startIcon={<SearchIcon fontSize="large" />}
                size="medium"
                onClick={onSearchClick}
                style={{width : "100%", height :"100%"}}
                >
                Keresés
              </Button>   
            </Grid>
          </Grid>      
        </div>



        <AdminUsersTableToolbar numSelected={selected.length} selectRow={selectedRow} />    {/* Felhasználók */}
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            //size={dense ? 'small' : 'medium'}
            size='small'
          >
            <HeadHuntingTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
             
              {hhtableSort(rows, getComparator(order, orderBy))
                //.filter((row) => row.companyCompanyName === 'Cica2 kft.u' )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.bountyHunterLinkId,row.contactUserId,row.workplaceId );
                  const labelId = `headhunting-table-${index}`;
                  return (
                      <TableRow
                        hover
                        onClick={(event) => rowClick(event, row)}
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                        selected={isItemSelected}
                        style={
                          selectedRow.bountyHunterLinkId===row.bountyHunterLinkId ? {backgroundColor : "#5DADE2" } : {}
                        }                        
                      >
                      <TableCell padding="avatar"> </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {format(new Date(row.created),'yyyy-MM-dd')}
                      </TableCell>
                      <TableCell align="left">{row.companyCompanyName}</TableCell>
                      <TableCell align="right">{"#"+ Number((row.advertisementId*243)+9876)}</TableCell>
                      <TableCell align="left">{row.advertisementTitle}</TableCell>
                      <TableCell align="left">{row.contactUserName}</TableCell>
                      <TableCell align="right">{row.bonusSalary}</TableCell>
                      <TableCell align="left">{row.sharingUserUserName}</TableCell>
                      <TableCell align="right">{row.advertisementBountyHunterFee}</TableCell>
                      <TableCell align="left">{row.sharingUserBankAccountNumber}</TableCell>
                      <TableCell align="left">{format(new Date(row.workplaceCreated),'yyyy-MM-dd')}</TableCell> 
                      <Switch  
                         
                          onClick={(event) => rowClick(event, row)}
                          //defaultChecked={row.paymentStatus=='P' ? true  : false}
                          checked={row.paymentStatus==='P' ? true  : false}
                          onChange={paymenthandleSubmit} 
                          disabled={selectedRow.bountyHunterLinkId<0 ? true  : false}
                      /> 
                     {/*} <Chip variant="outlined" label={row.paymentStatus=='P' ? "Igen"  : "Nem"} />   
                      <Chip variant="outlined" label={row.paymentStatus} />    */}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}



const TableCellPaymentStatus = ({ paymentStatus }) => {
  switch (paymentStatus) {
    case 'N':
      return <td align="left">Fizetésre vár</td>;
    case 'U':
      return <td align="left">Nincs fizetve</td>;
    case 'P':
      return <td align="left">Fizetett</td>;
    case 'C':
      return <td align="left">Törölt</td>;
    default:
      return <td align="left">Ismeretlen</td>;
  }
};


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import '../Login.css';
import axios from 'axios';
import * as Global from '../Global';

import { useHistory } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import sha512_256 from 'js-sha512';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="http://www.hardbit.hu/">
        Hardbit Kft
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fixme!!!  IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LoginButtonStyle = { width: "100%" ,marginTop: "20px"}

export default function Login(props) {
  const [email, setEmail]       = useState("");
  const [password, setPassword] = useState("");
  //const [token, setToken]       = useState("");
  const history = useHistory();

  function validateForm() {
    return email.length > 1 && password.length > 5;
  }

  function handleSubmit(event) {
    event.preventDefault();
  }

  const divStyle = {
    marginTop: 25    
  };

  const sendLogin =async()=> {
    try {
      const {data: response} = await axios(Global.apiBaseURL + 'adminUserLogin', {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          email:     email,
          password:  sha512_256(password)
        },
      });
      //response=JSON.parse(response);
      if (response !== undefined){
        if(response.status === 200) {
          if (response.data !== undefined) {
            sessionStorage.setItem("accessToken", response.data.token);
            localStorage.setItem("user-email", email);
            localStorage.setItem("user-id", response.data.adminUserId);
            localStorage.setItem("user-picture", response.data.profilePicture);
            //history.push("/Home");
            history.push("/Dashboard");
            props.setIsAuthenticated(true);
          } 
          else {
            toast.warn(response.error.message, {
              position: toast.POSITION.TOP_CENTER
            });
          }
        }
      }
    }
    catch (e) {
      toast.error(e, {
        position: toast.POSITION.TOP_CENTER
      });
    } 
  }

  return (
    <div className="Login">

    <Form onSubmit={handleSubmit}>
      <ToastContainer pauseOnFocusLoss={false}  toastClassName={'toastify-toast'} bodyClassName={'toastify-body'} />
      
      <img src="refbooklogo.svg" 
        style={{width: "70%" , margin: "auto", display: "block"}}
      />
      <Typography 
        component="h1" 
        variant="h5"
        color="textSecondary"
        style={divStyle}
        >
        Bejelentkezés
      </Typography>
      <Form.Group size="lg" controlId="user">
      <TextField 
          id="user" 
          value={email}
          autoFocus
          margin="normal"
          required
          fullWidth
          label="E-mail cím:" variant="outlined" 
          onChange={(e) => setEmail(e.target.value)}
          style={divStyle}
          autoComplete="email"
        />
      </Form.Group>

      <Form.Group size="lg" controlId="password">
        <TextField 
          id="password" 
          required
          fullWidth
          label="Jelszó" variant="outlined" 
          type="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={divStyle}
          autoComplete="password"
        />
      </Form.Group>
      <Button variant="contained" color="primary" 
              disabled={!validateForm()} 
              className="LoginButton"  
              type="submit"
              onClick={() => sendLogin()}
              style={LoginButtonStyle}
              >  
        Belépés
      </Button>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Form>
  </div>
  );
}

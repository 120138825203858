import { Avatar, Box, Card, CardContent, Grid, Typography } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ApartmentIcon from '@mui/icons-material/Apartment';

export const TotalCompany = (props) => (
  <Card {...props}>
    <CardContent>
      <Grid
        container
        spacing={3}
        sx={{ justifyContent: 'space-between' }}
      >
        <Grid item>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="overline"
          >
            Aktív cégek száma:
          </Typography>
          <Typography
            color="textPrimary"
            variant="h4"
          >
             {props.numberOfActiveCompanies}
          </Typography>
        </Grid>
        <Grid item>
          <Avatar
            sx={{
              backgroundColor: 'success.main',
              height: 56,
              width: 56
            }}
          >
            <ApartmentIcon />
          </Avatar>
        </Grid>
      </Grid>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          pt: 2
        }}
      >
        {props.numberOfActiveCompanies>props.numberOfCompaniesInTheCurrentMonth 
          ? <ArrowUpwardIcon color="success" />
          : <ArrowDownwardIcon color="error" />      
        }
        <Typography
          variant="body2"
          sx={{
            mr: 1
          }}
        >
          {props.numberOfCompaniesInTheCurrentMonth}
        </Typography>
        <Typography
          color="textSecondary"
          variant="caption"
        >
          utolsó hónapban
        </Typography>
      </Box>
    </CardContent>
  </Card>
);
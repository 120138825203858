//import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@mui/material';


import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { SeverityPill } from '../components/severity-pill';

import { apiBaseURL } from '../Global';
import Avatar from '@mui/material/Avatar';
import { format, formatDistanceToNow } from 'date-fns'

import { hu } from 'date-fns/locale';



const orders = [
  {
    userId: 1,
    email: 'valami@valami.hu',
    fullName: 'Ó Ede',
    created: "2023-01-10T12:07:17.000Z",
  },
  {
    userId: 2,
    email: 'valami@valami.hu',
    fullName: 'Ó Ede',
    created: "2023-01-10T12:07:17.000Z",
  },
  {
    userId: 3,
    email: 'valami@valami.hu',
    fullName: 'Ó Ede',
    created: "2023-01-10T12:07:17.000Z",
  },
  {
    userId: 4,
    email: 'valami@valami.hu',
    fullName: 'Ó Ede',
    created: "2023-01-10T12:07:17.000Z",
  },
  {
    userId: 5,
    email: 'valami@valami.hu',
    fullName: 'Ó Ede',
    created: "2023-01-10T12:07:17.000Z",
  },
  {
    userId: 6,
    email: 'valami@valami.hu',
    fullName: 'Ó Ede',
    created: "2023-01-10T12:07:17.000Z",
  }
];

export const LastestUsers = (props) => (
  <Card {...props}>
    <CardHeader title="Legújabb felhasználók" />
      <Box sx={{ minWidth: 800 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {/*Avatar*/}
              </TableCell>
              <TableCell>
                Név
              </TableCell>
              <TableCell>
                E-mail
              </TableCell>
              <TableCell sortDirection="desc">
                <Tooltip
                  enterDelay={300}
                  title="Sort"
                >
                  <TableSortLabel
                    direction="desc"
                  >
                    Regisztráció
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell>
                {/*eltelt idő*/}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.latestUsersData.map((user) => (
              <TableRow
                hover
                key={user.userId}
              >
                <TableCell padding="space">
                  <Avatar alt={user.fullName} src={apiBaseURL+user.profilePicture} />
                </TableCell>
                <TableCell>
                {user.fullName}
                </TableCell>
                <TableCell>
                {user.email}
                </TableCell>
                <TableCell>
                  {format(new Date(user.created),'yyyy-MM-dd HH:mm')}
                </TableCell>
                <TableCell>
                  {formatDistanceToNow(
                    new Date(user.created),
                    {includeSeconds: true, locale: hu}
                    )}
                </TableCell>
                <TableCell>
                  {/*
                    <SeverityPill
                      color={(user.status === 'delivered' && 'success')
                      || (user.status === 'refunded' && 'error')
                      || 'warning'}
                    >
                      {user.status}
                    </SeverityPill>
                  */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
  
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
      </Box>
  </Card>
);